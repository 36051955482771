import axios from "axios";
import React, { useEffect, useState } from "react";
import { thousandSeperator, formatDate } from "../utils/Format";
import BackButton from "./BackButton";

export default function CarDetails(props) {
    const { car_id, highestBids, wholecar, backBtn, setCarDetails } = props;
    const [car, setCar] = useState(null);


    useEffect(() => {
        getCar()
        // console.log('highsy', highestBids);
    }, [car_id])

    const getCar = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/cars/car/${car_id}`)
            .then(res => {
                // console.log(res.data);
                if (typeof res.data !== 'string') {
                    setCar(res.data);
                    setCarDetails(res.data)
                } else {
                    // console.log(res.data);
                }
            }).catch(err => {
                console.log(err.response.data);
            });
    }




    return (
        <>
            {car &&
                <section className="car-details-container">
                    {backBtn
                        &&
                        <BackButton />
                    }
                    <div className="car-details-header">

                        {highestBids && highestBids.length > 0
                            &&
                            <div className="left">
                                {highestBids
                                    ?
                                    <>
                                        {wholecar
                                            ?
                                            <>
                                                {
                                                    highestBids.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                highestBids.length === 1 && highestBids[0].car_or_body === 'Whole Car'
                                                                    ?
                                                                    <>
                                                                        <span className={`bid-status-icon ${highestBids[0].bid_status}`}></span>
                                                                    </>

                                                                    : ''
                                                            }
                                                            {
                                                                highestBids.length === 2
                                                                    ?
                                                                    <>
                                                                        <span className={`bid-status-icon ${highestBids[0].bid_status}`}></span>
                                                                    </>

                                                                    : ''
                                                            }
                                                        </>

                                                        : ''
                                                }
                                            </>

                                            :
                                            <>
                                                {
                                                    highestBids.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                highestBids.length === 1 && highestBids[0].car_or_body === 'Body Only'

                                                                    ?
                                                                    <>
                                                                        <span className={`bid-status-icon ${highestBids[0].bid_status}`}></span>
                                                                    </>

                                                                    : ''
                                                            }
                                                            {
                                                                highestBids.length === 2
                                                                    ?
                                                                    <>
                                                                        <span className={`bid-status-icon ${highestBids[1].bid_status}`}></span>
                                                                    </>

                                                                    : ''
                                                            }
                                                        </>

                                                        : ''
                                                }
                                            </>
                                        }
                                    </>

                                    : ''
                                }
                            </div>
                        }

                        <div className="right">
                            <h2 className="car-title" style={{marginBottom:'1.2rem'}}>{car.model}</h2>
                            {/* <h3 className="car-subtitle">${thousandSeperator(car.car_cost)} Onwards</h3> */}
                        </div>
                    </div>
                    <div className='car-info-table'>
                        <div className='table-row'><h3 className="table-title">Car Information</h3></div>

                        <div className='table-row'>
                            <div className='table-data-title'>Car Regn No.</div>
                            <div className='table-data'>{car.car_reg}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>Model</div>
                            <div className='table-data'>{car.model}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>Color</div>
                            <div className='table-data'>{car.colour}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>YOM</div>
                            <div className='table-data'>{car.yom}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>Regn Date</div>
                            <div className='table-data'>{car.regn_date ?formatDate(car.regn_date) :'-'}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>Mileage</div>
                            <div className='table-data'>{car.mileage}km</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>OMV</div>
                            <div className='table-data'>${parseInt(car.omv).toLocaleString('en-US')}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>Owners</div>
                            <div className='table-data'>{car.owners}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>Paper Value</div>
                            <div className='table-data'>${thousandSeperator(car.papervalue)}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>ARF</div>
                            <div className='table-data'>${thousandSeperator(car.arf)}</div>
                        </div>
                        <div className='table-row'>
                            <div className='table-data-title'>Road Tax Expiry</div>
                            <div className='table-data'>{car.roadtax_expiry ?formatDate(car.roadtax_expiry) :'-'}</div>
                        </div>
                        <div className='table-row remark'>
                            <div className='table-data-title remark'>Remarks</div>
                            <div className='table-data remark'>{car.remarks}</div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}