import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

const BackButton = ({mt}) => {
    const navigate = useNavigate()
    return (
        <Box sx={{mt:mt}} className='back-btn-2' onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon />
        </Box>
    )
}

export default BackButton