import { RiAuctionFill } from 'react-icons/ri'
import { IoReceiptSharp } from 'react-icons/io5'
import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Footer = () => {
    const [isLoggedIn, setIsLoggedIn] = useState('')

    useEffect(() => {
        if (sessionStorage.getItem("LoggedUser")) {
            setIsLoggedIn(JSON.parse(sessionStorage.getItem("LoggedUser")));
        }
        return () => setIsLoggedIn(false);
    }, [])
    return (
        <>
            {isLoggedIn.role === 'dealer'
                &&
                <footer>
                    <NavLink to='/'
                    ><RiAuctionFill />
                        Auction
                    </NavLink>

                    <NavLink to='/biddings'
                    ><IoReceiptSharp />
                        Biddings
                    </NavLink>
                </footer>
            }
        </>
    )
}

export default Footer