import React from 'react'
import { UserPage } from '../components'
import ChangeEmailForm from '../components/ChangeEmailForm'

const UpdateEmail = () => {
  return (
    <UserPage>
      <ChangeEmailForm />
    </UserPage>
  )
}

export default UpdateEmail