
import { AiOutlinePoweroff } from 'react-icons/ai'

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HiMenuAlt1 } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AdminNavbar from './AdminNavbar';
import AuditorNavbar from './AuditorNavbar';
import { isAuditor } from '../utils/AuthSession';


function Navbar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(true)


    useEffect(() => {
        if (sessionStorage.getItem("LoggedUser")) {
            setIsLoggedIn(JSON.parse(sessionStorage.getItem("LoggedUser")));
        }
        return () => setIsLoggedIn(false);
    }, []);


    function CommonUserNavbar() {
        const [isLoggedIn, setIsLoggedIn] = useState(false);
        const [state, setState] = React.useState({
            top: false,
            left: false,
            bottom: false,
            right: false,
        });

        const logout = () => {
            sessionStorage.clear();
            window.location = '/';
        }

        const toggleDrawer = (anchor, open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }

            setState({ ...state, [anchor]: open });
        };

        const list = (anchor) => (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <div className='menu-link-container'>
                    <Link to='/account'>Account</Link>
                    <Link to='/updatePassword'>Update Password</Link>
                    <Link to='/updateEmail'>Update Email</Link>
                    <Link onClick={() => logout()}>Logout</Link>
                </div>
            </Box>
        );

        useEffect(() => {
            if (sessionStorage.getItem("LoggedUser")) {
                setIsLoggedIn(JSON.parse(sessionStorage.getItem("LoggedUser")));
            }
            return () => setIsLoggedIn(false);
        }, []);


        return (
            <>
                {isLoggedIn
                    &&
                    <header>
                        <div>
                            {['right'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <Button onClick={toggleDrawer(anchor, true)}><HiMenuAlt1 title='Menu' className='menu-icon' /></Button>
                                    <Drawer
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                    >
                                        {list(anchor)}
                                    </Drawer>
                                </React.Fragment>
                            ))}
                        </div>

                    </header>

                }
            </>
        )
    };


    return (
        <>
            {isLoggedIn.role === 'admin'
                ? <AdminNavbar />
                : <>
                    {isAuditor()
                        ? <AuditorNavbar />
                        : <CommonUserNavbar />
                    }
                </>
            }
        </>
    )


}

export default Navbar;