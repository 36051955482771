import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';
import { Backdrop, Fade, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import { getUser } from '../utils/AuthSession';

const filter = createFilterOptions();

export default function CompanySelect({ setCompany }) {
    const [value, setValue] = React.useState(null);
    const [companies, setCompanies] = React.useState([])
    const [refresh, setRefresh] = React.useState(false)

    const [companiesModal, setCompaniesModal] = React.useState(false)
    const openCompaniesModal = () => setCompaniesModal(true)
    const closeCompaniesModal = () => setCompaniesModal(false)
    const [loading, setLoading] = React.useState(false)


    const [open, toggleOpen] = React.useState(false);
    const handleClose = () => {
        setDialogValue({
            company_name: ''
        });
        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState({
        company_name: ''
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const company_name = dialogValue.company_name
        axios.post(`${process.env.REACT_APP_API_URL}/companies/addCompany`, { company_name, user_id: getUser().id })
            .then(res => {
                alert(res.data)
                setRefresh(!refresh)
            })
            .catch(err => {
                alert(err.response.data)
            })


        handleClose();
    };

    React.useEffect(() => {
        fetchCompanies()

        const input = document.getElementById('company-select');
        input.setAttribute('required', true)

    }, [refresh])

    const fetchCompanies = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/companies/allCompanies`)
            .then(res => {
                setCompanies(res.data)
            })
            .catch(err => {
                alert(err.response.data)
            })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '50rem',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        border: 'none',
        maxHeight: '60vh',
        overflow: 'hidden'
    };

    const deleteCompany = (company) => {
        console.log('deleting company with id - ', company.id)

        clearAllErrorMessages()

        const selectedBtn = document.getElementById(`company-delete-btn-${company.id}`)
        selectedBtn.setAttribute('value', 'Deleting...')
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/companies/deleteCompany`, { company_id: company.id, company_details: company, user_id: getUser().id })
            .then(res => {
                setLoading(false)
                selectedBtn.setAttribute('value', 'Delete')
                alert(res.data)
                setRefresh(!refresh)
            })
            .catch(err => {
                setLoading(false)
                selectedBtn.setAttribute('value', 'Delete')
                alert(err.response.data)
            })
    }

    const updateCompany = (company) => {
        clearAllErrorMessages()

        //get error field for the selected id
        const errorField = document.getElementById(`company-error-${company['company'].id}`)

        //get textbox value of selected id
        const updated_value = document.getElementById(`company-input-${company['company'].id}`).value

        if (updated_value) {
            // console.log('updating company with id - ', company.id, ' and value - ', updated_value)

            const selectedBtn = document.getElementById(`company-update-btn-${company['company'].id}`)
            selectedBtn.setAttribute('value', 'Updating...')

            axios.post(`${process.env.REACT_APP_API_URL}/companies/updateCompany`, { company_id: company['company'].id, company_name:company['company'].company_name, updated_company_name: updated_value, user_id: getUser().id })
                .then(res => {
                    setLoading(false)
                    selectedBtn.setAttribute('value', 'Update')
                    alert(res.data)
                    setRefresh(!refresh)
                })
                .catch(err => {
                    setLoading(false)
                    selectedBtn.setAttribute('value', 'Update')
                    alert(err.response.data)
                })
        } else {
            errorField.innerHTML = 'Please fill in the new name for company'
            // console.log('Please fill the field');
        }
    }


    const clearAllErrorMessages = () => {
        //delete all error messages
        let company_error = document.getElementsByClassName('company-error')
        for (let c = 0; c < company_error.length; c++) {
            // console.log(company_error[c]);
            company_error[c].innerHTML = ''
        }
    }

    return (
        <React.Fragment>
            <Autocomplete
                className='company-select'
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                company_name: newValue
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                            company_name: newValue.inputValue
                        });
                    } else {
                        setCompany(newValue)
                        setValue(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            company_name: `Add "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                id="company-select"
                options={companies}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.company_name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.company_name}</li>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => <TextField {...params} label="Select Company *" />}
            />
            <p className='manage-companies-btn' onClick={()=>openCompaniesModal()}>Manage Companies</p>
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Add a new company</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue.company_name}
                            onChange={(event) =>
                                setDialogValue({
                                    ...dialogValue,
                                    company_name: event.target.value,
                                })
                            }
                            label="Company Name"
                            type="text"
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Modal
                open={companiesModal}
                onClose={closeCompaniesModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='manage-companies-modal'
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Manage Companies
                    </Typography>
                    <div className='companies-container'>
                        {companies.map((company, i) => {
                            return (
                                <div key={i} className='company'>
                                    <div className='top'>
                                        <input type='text' placeholder={company.company_name} id={`company-input-${company.id}`} required onChange={()=>clearAllErrorMessages()} />
                                        <button
                                            value={JSON.stringify({company})}
                                            onClick={e => updateCompany(JSON.parse(e.target.value))}
                                            className='company-update-btn'
                                            id={`company-update-btn-${company.id}`}
                                            disabled={loading}
                                        >
                                            <ReplayIcon /> Update
                                        </button>

                                        <button
                                            value={JSON.stringify(company)}
                                            onClick={e => deleteCompany(JSON.parse(e.target.value))}
                                            className='company-delete-btn'
                                            id={`company-delete-btn-${company.id}`}
                                            disabled={loading}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>

                                    <span className='company-error' id={`company-error-${company.id}`}></span>
                                </div>
                            )
                        })}
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
}
