import React, { useEffect, useState } from "react";
import {
    Link,
    useNavigate,
    // useNavigate,
    useParams
} from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import { AdminPage, DataTable, EditImagePositionForm, SwipeableTextMobileStepper, TabPanel, UploadCarImageForm, UploadFileForm } from "../components";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import common from "@mui/material/colors/common";
import Modal from "@mui/material/Modal";
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableContainer from "@mui/material/TableContainer";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getUser, isFinance } from "../utils/AuthSession";
import CollectionsIcon from '@mui/icons-material/Collections';
import CloseIcon from '@mui/icons-material/Close';
import { deleteFile, listFiles } from "../utils/S3";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatDate, formatDate2, formatDateTime, thousandSeperator } from "../utils/Format";
import ImageCarousel from "../components/ImageCarousel";
import axios from "axios";
import { Parser } from 'json2csv'

export default function ViewBidSessionCarsPage() {
    const { session_no } = useParams();
    const [bidSession, setBidSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const getBidSession = (session_no) => {
        axios.get(`${process.env.REACT_APP_API_URL}/bidSessions/bidsession/${session_no}`)
            .then(res => {
                if (res.data && typeof res.data !== 'string') {
                    console.log('bidsession - ', bidSession);
                    setBidSession(res.data)
                } else {
                    alert(res.data);
                }
                setLoading(false);
            }).catch(err => {
                console.log(err.response.data);
                setLoading(false);
            });
    }
    useEffect(() => {
        if (session_no) {
            getBidSession(session_no);
        }
        return () => setBidSession(null);
    }, [session_no]);
    return (
        <AdminPage>
            <Container maxWidth="lg">
                <CssBaseline />
                {bidSession ?
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                        }}
                    >
                        <div className="flex ">
                            <button className="back-btn" onClick={() => navigate(-1)}>
                                <ArrowBackIcon /> Back
                            </button>
                            <Typography component="h1" variant="h5">
                                Session {session_no}- Car List
                            </Typography>
                        </div>

                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        {isFinance()
                                            ?
                                            <CarsManagementTabs>
                                                <Cars session={bidSession} />
                                                <UploadFileForm session_id={bidSession.id} />
                                            </CarsManagementTabs>

                                            :
                                            <Cars session={bidSession} />
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    : <>{!loading && <Typography sx={{ mt: 2 }}>Requested Session Not Found</Typography>}</>
                }
            </Container>
        </AdminPage>
    );
}
function CarsManagementTabs(props) {
    const { children } = props
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className='tab-container' sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs className='tabs' value={value} onChange={handleChange} centered>
                <Tab className='tab' label="Cars" />
                <Tab className='tab' label="Add Car" />
            </Tabs>

            <TabPanel value={value} index={0}>
                {children[0]}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {children[1]}
            </TabPanel>
        </Box>
    );
}
function Cars(props) {
    const { session } = props;
    const [cars, setCars] = useState([]);
    const showTooltip = (params) => {
        if (params.row[params.field]) {
            return (
                <Tooltip title={params.row[params.field]} placement="top">
                    <label>
                        {params.row[params.field]}
                    </label>
                </Tooltip>
            );
        }
    }
    const cs = { headerAlign: 'left', align: 'left', renderCell: showTooltip };
    const sc = { maxWidth: 50 };
    const mc = { maxWidth: 80 };
    const columns = [
        // { field: 'id', headerName: 'ID', ...cs, ...sc, },
        {
            field: 'actions', headerName: 'Actions', headerAlign: 'center', align: 'center', sortable: false, minWidth: 150, renderCell: (params) => {
                return (
                    <CarListActions session={session} row={params.row} />
                );
            }
        },
        {
            field: 'bids', headerName: 'Bids', ...cs, ...sc,
            renderCell: (params) => {
                if (isFinance()) {
                    return <Link to={`/selectWinner/${params.row.id}`}>{params.row.bids}</Link>;
                } else {
                    return params.row.bids
                }
            }
        },
        { field: 'model', headerName: 'Model', ...cs, },
        { field: 'car_reg', headerName: 'Regn No', ...cs, },
        {
            field: 'max_bidder_wholecar_username', headerName: 'Max Bidder Wholecar', ...cs, minWidth: '140',
            renderCell: params => {
                if (params.row.max_bidder_wholecar_username) {
                    return params.row.max_bidder_wholecar_username
                } else {
                    return '-'
                }
            }
        },
        {
            field: 'max_bid_wholecar', headerName: 'Max Bid Wholecar', ...cs, minWidth: '140',
            renderCell: params => {
                if (params.row.max_bid_wholecar) {
                    return `$${thousandSeperator(params.row.max_bid_wholecar)}`
                } else {
                    return '-'
                }
            }
        },
        {
            field: 'gp_wholecar', headerName: 'GP Whole Car', ...cs, minWidth: '140',
            renderCell: (params) => {
                return `
                ${params.row.gp_wholecar < 0
                        ? `-$${thousandSeperator(params.row.gp_wholecar * -1)}`
                        : `$${thousandSeperator(params.row.gp_wholecar)}`
                    }
                `
            }
        },
        {
            field: 'max_bidder_bodyonly_username', headerName: 'Max Bidder Bodyonly', ...cs, minWidth: '140',
            renderCell: params => {
                if (params.row.max_bidder_bodyonly_username) {
                    return params.row.max_bidder_bodyonly_username
                } else {
                    return '-'
                }
            }
        },
        {
            field: 'max_bid_bodyonly', headerName: 'Max Bid Bodyonly', ...cs, minWidth: '140',
            renderCell: params => {
                if (params.row.max_bid_bodyonly) {
                    return `$${thousandSeperator(params.row.max_bid_bodyonly)}`
                } else {
                    return '-'
                }
            }
        },
        {
            field: 'gp_bodyonly', headerName: 'GP Body Only', ...cs, minWidth: '140',
            renderCell: (params) => {
                return `
                ${params.row.gp_bodyonly < 0
                        ? `-$${thousandSeperator(params.row.gp_bodyonly * -1)}`
                        : `$${thousandSeperator(params.row.gp_bodyonly)}`
                    }
                    `
            }
        },
        { field: 'colour', headerName: 'Colour', ...cs },
        { field: 'yom', headerName: 'YOM', ...cs, ...sc, },
        {
            field: 'regn_date', headerName: 'Regn Date', ...cs,
            renderCell: params => {
                return `${formatDate(params.row.regn_date)}`
            }
        },
        { field: 'mileage', headerName: 'Mileage', ...cs },
        {
            field: 'omv', headerName: 'OMV', ...cs, ...mc,
            renderCell: (params) => {
                return `$${thousandSeperator(params.row['omv'])}`
            }
        },
        { field: 'owners', headerName: 'Owners', ...cs, ...mc, },
        {
            field: 'papervalue', headerName: 'Papervalue', ...cs,
            renderCell: (params) => {
                return `$${thousandSeperator(params.row['papervalue'])}`
            }
        },
        {
            field: 'arf', headerName: 'ARF', ...cs,
            renderCell: (params) => {
                return `$${thousandSeperator(params.row['arf'])}`
            }
        },
        {
            field: 'minbidvalue_wholecar', headerName: 'Whole Car Reserve Price', ...cs,
            renderCell: (params) => {
                return `$${thousandSeperator(params.row['minbidvalue_wholecar'])}`
            }
        },
        {
            field: 'minbidvalue_bodyonly', headerName: 'Body Only Reserve Price', ...cs,
            renderCell: (params) => {
                return `$${thousandSeperator(params.row['minbidvalue_bodyonly'])}`
            }
        },
        { field: 'remarks', headerName: 'Remarks', ...cs, minWidth: '200' },
        {
            field: 'roadtax_expiry', headerName: 'Road Tax Expiry', ...cs,
            renderCell: (params) => {
                return formatDate(params.row.roadtax_expiry)
            }
        },
        { field: 'roadtax_rebate', headerName: 'Road Tax Rebate', ...cs },
        {
            field: 'car_cost', headerName: 'Car Cost', ...cs,
            renderCell: (params) => {
                return `$${thousandSeperator(params.row['car_cost'])}`
            }
        },
        // { field: 'reserveprice_wholecar', headerName: 'reserveprice_wholecar', ...cs },
        // { field: 'reserveprice_bodyonly', headerName: 'reserveprice_bodyonly', ...cs },
    ];

    const getCars = (session_id) => {
        const user = getUser();
        axios.post(`${process.env.REACT_APP_API_URL}/admins/cars`,
            {
                username: user.username,
                user_id: user.id,
                session_id: session_id
            }
        )
            .then(res => {
                if (res.data && res.data.length > 0 && typeof res.data !== 'string') {
                    console.log('car list - ', res.data);
                    setCars(res.data)
                } else {
                    alert(res.data);
                }
            }).catch(err => {
                console.log(err.response.data);
            });
    }
    useEffect(() => {
        if (session && session.id) {
            getCars(session.id);
        }
        return () => setCars([]);
    }, [session]);

    const exportCarList = () => {
        //create csv file of the data
        const fileName = `Session ${session.session_no} - Car List`
        console.log(fileName);
        const updatedCarList = []
        cars.forEach((car, i) => {
            updatedCarList.push({
                'ID': i + 1,
                'Bids': car.bids,
                'Model': car.model,
                'Regn No': car.car_reg,
                'Max Bidder Whole Car': car.max_bidder_wholecar_username,
                'Max Bid Whole Car': car.max_bid_wholecar,
                'GP Whole Car': car.gp_wholecar,
                'Max Bidder Body Only': car.max_bidder_bodyonly_username,
                'Max Bid Body Only': car.max_bid_bodyonly,
                'GP Body Only': car.gp_bodyonly,
                'Colour': car.colour,
                'YOM': car.yom,
                'Mileage': car.mileage,
                'OMV': car.omv,
                'Owners': car.owners,
                'Paper Value': car.papervalue,
                'ARF': car.arf,
                'Whole Car Reserved Price': car.minbidvalue_wholecar,
                'Body Only Reserved Price': car.minbidvalue_bodyonly,
                'Remarks': car.remarks,
                'Road Tax Expiry': car.roadtax_expiry,
                'Road Tax Rebate': car.roadtax_rebate,
                'Car Cost': car.car_cost,
            })
        })
        let fields = ['ID', 'Bids', 'Model', 'Regn No', 'Max Bidder Whole Car', 'Max Bid Whole Car', 'GP Whole Car', 'Max Bidder Body Only', 'Max Bid Body Only', 'GP Body Only', 'Colour', 'YOM', 'Mileage', 'OMV', 'Owners', 'Paper Value', 'ARF', 'Whole Car Reserved Price', 'Body Only Reserved Price', 'Remarks', 'Road Tax Expiry', 'Road Tax Rebate', 'Car Cost'];
        const opts = { fields };

        const parser = new Parser(opts);
        const csv = parser.parse(updatedCarList);

        downloadFile({
            data: csv,
            fileName: fileName,
            fileType: 'text/csv'
        })
    }
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    return (
        <>
            <Box sx={{ padding: `${!isFinance() ? '1.7rem' : ''}` }}>
                <div className="header-flexed">
                    <div />
                    <div className="header-right-aligned">
                        <Tooltip
                            title="Export Car List"
                            placement="top"
                        >
                            <button className="btn light-btn btn-pill" onClick={() => exportCarList()}>Export Car List</button>
                        </Tooltip>
                    </div>
                </div>
                <DataTable
                    rows={cars}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    rowHeight={50}
                    disableSelectionOnClick={false}
                    style={{ height: '65vh' }}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 500}
                />
            </Box>
        </>
    )
}

class UpdateCarForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            car: props.car || {},
            update: {},
            session_id: props.session_id || '',
            submitted: false,
            images: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ update: { ...this.state.update, [name]: value } });
    }
    handleSubmit(e) {
        e.preventDefault();
        if (Object.keys(this.state.update).length > 0) {
            console.log(this.state.update);
            this.setState({ submitted: true });
            this.updateCar(this.state.session_id, this.state.car.id, this.state.update)
        }
    }
    handleDelete(e) {
        e.preventDefault();
        if (this.state.session_id && this.state.car.id) {
            if (window.confirm("Delete Car?")) {
                this.setState({ submitted: true });
                this.deleteCar(this.state.session_id, this.state.car.id);
            }
        }
    }
    updateCar = (session_id, car_id, update) => {
        const user = getUser();
        console.log(JSON.stringify({
            user_id: user.id,
            username: user.username,
            session_id: session_id,
            car_id: car_id,
            update: update,
            oldCarData: this.state.car
        }));
        axios.post(`${process.env.REACT_APP_API_URL}/admins/editcar`,
            {
                user_id: user.id,
                username: user.username,
                session_id: session_id,
                car_id: car_id,
                update: update,
                oldCarData: this.state.car
            }
        )
            .then(res => {
                alert(res.data);
                window.location.reload(false);
                this.setState({ submitted: false });
            }).catch(err => {
                console.log(err.response.data);
                this.setState({ submitted: false });
            });
    }

    deleteCar = (session_id, car_id) => {
        const user = getUser();
        axios.post(`${process.env.REACT_APP_API_URL}/admins/deletecar`,
            {
                user_id: user.id,
                username: user.username,
                session_id: session_id,
                car_id: car_id,
                model: this.state.car.model,

            }
        )
            .then(res => {
                alert(res.data);
                window.location.reload(false);
                this.setState({ submitted: false });
            }).catch(err => {
                console.log(err.response.data);
                this.setState({ submitted: false });
            });
    }

    render() {
        return (
            <Box component="form" className="edit-car-form" onSubmit={this.handleSubmit} sx={{ mt: 1, overflow: 'scroll', display: 'flex-inline', flexDirection: 'column', height: 530 }}>

                {/* {Object.keys(this.state.car).length > 0 &&
                    Object.keys(this.state.car).map((key, i) => {
                        if (key !== 'id' && key !== 'session_id' && key !== 'image' && key !== 'bids') {
                            return (
                                <TextField
                                    key={i}
                                    margin="normal"
                                    id={key}
                                    label={key}
                                    name={key}
                                    disabled={false}
                                    value={this.state.update.hasOwnProperty(key) ? this.state.update[key] : this.state.car[key]}
                                    onChange={this.handleChange}
                                // sx={{ ml: 4 }}
                                />
                            );
                        }
                        return false;
                    })
                } */}

                <TextField
                    margin="normal"
                    id='car_reg'
                    label='Regn No'
                    name='car_reg'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('car_reg') ? this.state.update['car_reg'] : this.state.car['car_reg']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='model'
                    label='Model'
                    name='model'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('model') ? this.state.update['model'] : this.state.car['model']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='colour'
                    label='Colour'
                    name='colour'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('colour') ? this.state.update['colour'] : this.state.car['colour']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='yom'
                    label='YOM'
                    name='yom'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('yom') ? this.state.update['yom'] : this.state.car['yom']}
                    onChange={this.handleChange}
                />
                <div className="date-input">
                    <input
                        type='date'
                        margin="normal"
                        id='regn_date'
                        label='Regn Date'
                        name='regn_date'
                        disabled={false}
                        value={this.state.update.hasOwnProperty('regn_date') ? this.state.update['regn_date'] : formatDate2(new Date(new Date(this.state.car['regn_date']).setDate(new Date(this.state.car['regn_date']).getDate() + 1)))}
                        onChange={this.handleChange}
                    />
                    <label for='regn_date'>Regn Date</label>
                </div>
                <TextField
                    margin="normal"
                    id='mileage'
                    label='Mileage'
                    name='mileage'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('mileage') ? this.state.update['mileage'] : this.state.car['mileage']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='omv'
                    label='OMV'
                    name='omv'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('omv') ? this.state.update['omv'] : this.state.car['omv']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='owners'
                    label='Owners'
                    name='owners'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('owners') ? this.state.update['owners'] : this.state.car['owners']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='papervalue'
                    label='Paper Value'
                    name='papervalue'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('papervalue') ? this.state.update['papervalue'] : this.state.car['papervalue']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='minbidvalue_wholecar'
                    label='Min Bid Value Wholecar'
                    name='minbidvalue_wholecar'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('minbidvalue_wholecar') ? this.state.update['minbidvalue_wholecar'] : this.state.car['minbidvalue_wholecar']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='minbidvalue_bodyonly'
                    label='Min Bid Value Bodyonly'
                    name='minbidvalue_bodyonly'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('minbidvalue_bodyonly') ? this.state.update['minbidvalue_bodyonly'] : this.state.car['minbidvalue_bodyonly']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='remarks'
                    label='Remarks'
                    name='remarks'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('remarks') ? this.state.update['remarks'] : this.state.car['remarks']}
                    onChange={this.handleChange}
                />
                {/* <TextField
                    margin="normal"
                    id='roadtax_expiry'
                    label='Road Tax Expiry'
                    name='roadtax_expiry'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('roadtax_expiry') ? this.state.update['roadtax_expiry'] : this.state.car['roadtax_expiry']}
                    onChange={this.handleChange}
                /> */}
                <div className="date-input">
                    <input
                        type='date'
                        margin="normal"
                        id='roadtax_expiry'
                        label='Road Tax Expiry'
                        name='roadtax_expiry'
                        disabled={false}
                        value={this.state.update.hasOwnProperty('roadtax_expiry') ? this.state.update['roadtax_expiry'] : formatDate2(new Date(new Date(this.state.car['roadtax_expiry']).setDate(new Date(this.state.car['roadtax_expiry']).getDate() + 1)))}
                        onChange={this.handleChange}
                    />
                    <label for='regn_date'>Road Tax Expiry</label>
                </div>
                <TextField
                    margin="normal"
                    id='roadtax_rebate'
                    label='Road Tax Rebate'
                    name='roadtax_rebate'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('roadtax_rebate') ? this.state.update['roadtax_rebate'] : this.state.car['roadtax_rebate']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='car_cost'
                    label='Car Cost'
                    name='car_cost'
                    disabled={false}
                    value={this.state.update.hasOwnProperty('car_cost') ? this.state.update['car_cost'] : this.state.car['car_cost']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='max_bidder_wholecar_id'
                    label='Max Bidder(Wholecar) ID'
                    name='max_bidder_wholecar_id'
                    disabled={true}
                    value={this.state.update.hasOwnProperty('max_bidder_wholecar_id') ? this.state.update['max_bidder_wholecar_id'] : `${this.state.car['max_bidder_wholecar_id'] === null ? '-' : this.state.car['max_bidder_wholecar_id']}`}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='max_bidder_wholecar_username'
                    label='Max Bidder(Wholecar) Username'
                    name='max_bidder_wholecar_username'
                    disabled={true}
                    value={this.state.update.hasOwnProperty('max_bidder_wholecar_username') ? this.state.update['max_bidder_wholecar_username'] : `${this.state.car['max_bidder_wholecar_username'] === null ? '-' : this.state.car['max_bidder_wholecar_username']}`}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='max_bid_wholecar'
                    label='Max Bid(Wholecar)'
                    name='max_bid_wholecar'
                    disabled={true}
                    value={this.state.update.hasOwnProperty('max_bid_wholecar') ? this.state.update['max_bid_wholecar'] : this.state.car['max_bid_wholecar']}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='max_bidder_bodyonly_id'
                    label='Max Bidder(Bodyonly) ID'
                    name='max_bidder_bodyonly_id'
                    disabled={true}
                    value={this.state.update.hasOwnProperty('max_bidder_bodyonly_id') ? this.state.update['max_bidder_bodyonly_id'] : `${this.state.car['max_bidder_bodyonly_id'] === null ? '-' : this.state.car['max_bidder_bodyonly_id']}`}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='max_bidder_bodyonly_username'
                    label='Max Bidder(Bodyoly) Username'
                    name='max_bidder_bodyonly_username'
                    disabled={true}
                    value={this.state.update.hasOwnProperty('max_bidder_bodyonly_username') ? this.state.update['max_bidder_bodyonly_username'] : `${this.state.car['max_bidder_bodyonly_username'] === null ? '-' : this.state.car['max_bidder_bodyonly_username']}`}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="normal"
                    id='max_bid_bodyonly'
                    label='Max Bid(Bodyonly)'
                    name='max_bid_bodyonly'
                    disabled={true}
                    value={this.state.update.hasOwnProperty('max_bid_bodyonly') ? this.state.update['max_bid_bodyonly'] : this.state.car['max_bid_bodyonly']}
                    onChange={this.handleChange}
                />

                <div className='d-flex button-container'>

                    <button
                        className="btn primary-btn"
                        type="submit"
                        disabled={this.state.submitted}>
                        Update
                    </button>

                    <button
                        className="btn danger-btn"
                        onClick={this.handleDelete}
                        type="submit"
                        disabled={this.state.submitted}>
                        Delete*
                    </button>
                </div>

                <p>*Deleting the car will also delete all the bids that car have</p>
            </Box>
        );
    }
}

function ViewCar(props) {
    const { car } = props;
    // const images = [
    //     {
    //         label: 'San Francisco – Oakland Bay Bridge, United States',
    //         imgPath:
    //             'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    //     },
    //     {
    //         label: 'Bird',
    //         imgPath:
    //             'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    //     },
    //     {
    //         label: 'Bali, Indonesia',
    //         imgPath:
    //             'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    //     },
    //     {
    //         label: 'Goč, Serbia',
    //         imgPath:
    //             'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    //     },
    // ];
    const [images, setImages] = useState([]);

    useEffect(() => {
        const getCarImages = () => {
            axios.post(`${process.env.REACT_APP_API_URL}/cars/carimages`,
                {
                    car_id: car.id
                }
            )
                .then(res => {
                    if (res.data.length > 0 && typeof res.data !== 'string') {
                        setImages(res.data.map(image => {
                            return { label: image.name, imgPath: image.src };
                        }));
                    } else {
                        console.log(res.data);
                    }
                }).catch(err => {
                    console.log(err.response.data);
                });
        };
        getCarImages();
        return () => setImages([]);
    }, [car]);

    return (
        <Card sx={{ overflow: 'scroll', height: 500, }}>
            {/* {images.length > 0 ? <SwipeableTextMobileStepper images={images} />
                : <Typography>No Images</Typography>} */}
            <ImageCarousel images={images} size='sm' />
            <CardContent>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">Car Regn No.</TableCell>
                                <TableCell align="left">{car.car_reg}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Model</TableCell>
                                <TableCell align="left">{car.model}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Color</TableCell>
                                <TableCell align="left">{car.colour}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">YOM</TableCell>
                                <TableCell align="left">{car.yom}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Regn Date</TableCell>
                                <TableCell align="left">{formatDate(car.regn_date)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Mileage</TableCell>
                                <TableCell align="left">{car.mileage}km</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">OMV</TableCell>
                                <TableCell align="left">${thousandSeperator(car.omv)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Owners</TableCell>
                                <TableCell align="left">{car.owners}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Paper Value</TableCell>
                                <TableCell align="left">${thousandSeperator(car.papervalue)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">ARF</TableCell>
                                <TableCell align="left">${thousandSeperator(car.arf)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Road Tax Expiry</TableCell>
                                <TableCell align="left">{formatDate(car.roadtax_expiry)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Remarks</TableCell>
                                <TableCell align="left">{car.remarks}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}

function CarGallery(props) {
    const { car, session } = props;
    const dir = `cars/${session.session_no}_${car.id}/`;
    const [images, setImages] = useState([]);
    const [dbImages, setDbImages] = useState([]);
    // const images = [
    //     {
    //         label: 'San Francisco – Oakland Bay Bridge, United States',
    //         imgPath:
    //             'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    //     },
    //     {
    //         label: 'Bird',
    //         imgPath:
    //             'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    //     },
    //     {
    //         label: 'Bali, Indonesia',
    //         imgPath:
    //             'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    //     },
    //     {
    //         label: 'Goč, Serbia',
    //         imgPath:
    //             'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    //     },
    //     {
    //         label: 'image',
    //         imgPath: "https://wearnes-images-dev.s3.ap-southeast-1.amazonaws.com/cars/A_1/4.png"
    //     },
    //     {
    //         label: 'image',
    //         imgPath: "https://wearnes-images-dev.s3.ap-southeast-1.amazonaws.com/cars/A_1/4.png"
    //     }
    // ];

    const deleteImage = (image) => {
        console.log(image)
        if (window.confirm("Delete Image")) {
            deleteFile(image.label).then(data => {
                if (data) {
                    alert("Delete Success");
                    window.location.reload(false);
                } else {
                    alert("Delete Failed");
                }
            });

            axios.post(`${process.env.REACT_APP_API_URL}/cars/deletecarimg`,
                {
                    src: image.imgPath
                }
            )
                .then(res => {
                    alert(res.data);
                    window.location.reload(true);
                }).catch(err => {
                    console.log(err.response.data);
                });
        }
    }

    useEffect(() => {
        const getCarImages = () => {
            axios.post(`${process.env.REACT_APP_API_URL}/cars/carimages`,
                {
                    car_id: car.id
                }
            )
                .then(res => {
                    if (res.data.length > 0 && typeof res.data !== 'string') {
                        console.log('dbImg', res.data);
                        setDbImages(res.data);
                    } else {
                        console.log(res.data);
                    }
                }).catch(err => {
                    console.log(err.response.data);
                });
        };
        getCarImages();
        const getS3Images = () => {
            listFiles(dir).then(data => {
                console.log('listFile - ', data);
                if (data) {
                    setImages(data.map(image => {
                        return { label: image.Key, imgPath: `https://wearnes-images-dev.s3.ap-southeast-1.amazonaws.com/${image.Key}` }
                    }));
                }
            });
        }
        getS3Images();
        return () => setImages([]);
    }, [dir, car]);

    return (
        <Box sx={{ width: '100%' }}>
            <h3>Upload New Image</h3>
            <UploadCarImageForm dir={dir} car_id={car.id} session_id={session.id} fileName={images.length} />
            <br />
            <br />

            <h3>Delete Image</h3>
            <Card sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem', color: 'white' }}>
                {console.log('images.length', images.length)}
                {images.length > 0
                    ? images.map((image, i) => {
                        console.log(image);
                        return (
                            <Box className="delete-img-box" key={i} sx={{ width: '25%', height: '10rem', position: 'relative', backgroundImage: `url('${image.imgPath}')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                <span className="overlay" />
                                <IconButton onClick={() => deleteImage(image)} >
                                    <CloseIcon sx={{ color: 'white' }} />
                                    <Typography sx={{ color: 'white' }}>{image.label.split("/").pop()}</Typography>
                                </IconButton>
                                {/* <CardMedia component="img" alt={image.label} src={image.imgPath} /> */}
                            </Box>
                        );
                    })
                    : <Typography sx={{ color: 'black' }}>No Images</Typography>
                }
            </Card>
            <br />
            <br />

            <h3>Change Position</h3>
            <Box>
                {dbImages.length > 0
                    ? <EditImagePositionForm images={dbImages} car_id={car.id} />
                    : <Typography>No Images</Typography>
                }
            </Box>
        </Box >

    );
}

function CarListActions(props) {
    const [car, setCar] = useState();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Box className='view-cars-admin-actions' sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {isFinance()
                &&
                <EditCarAction car={car} setCar={setCar} {...props} style={style} />
            }
            {!isFinance()
                &&
                <CarGalleryAction car={car} setCar={setCar} {...props} style={style} />
            }
            <PreviewCarAction car={car} setCar={setCar} {...props} style={style} />
        </Box>
    );
}
function EditCarAction(props) {
    const { car, session, setCar, style, row } = props;
    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const editEvent = (e, row) => {
        e.stopPropagation();
        handleOpenEdit();
        setCar(row);
    };
    return (
        <>
            <Tooltip title="Edit Car" placement="top">
                <IconButton className='car-action-btn' onClick={(e) => editEvent(e, row)}>
                    <EditIcon sx={{ color: common.black }} />
                </IconButton>
            </Tooltip>
            <Modal
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='edit-car-modal'
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Car {session.session_no}
                    </Typography>
                    <UpdateCarForm car={car} session_id={session.id} />
                </Box>
            </Modal >
        </>
    );
}
function CarGalleryAction(props) {
    const { car, session, setCar, style, row } = props;
    const [openGallery, setOpenGallery] = useState(false);
    const handleOpenGallery = () => setOpenGallery(true);
    const handleCloseGallery = () => setOpenGallery(false);
    const galleryEvent = (e, row) => {
        e.stopPropagation();
        handleOpenGallery();
        setCar(row);
    }
    return (
        <>
            <Tooltip title="View Gallery" placement="top" >
                <IconButton className='car-action-btn' onClick={(e) => galleryEvent(e, row)} >
                    <CollectionsIcon sx={{ color: common.black }} />
                </IconButton>
            </Tooltip>
            <Modal
                open={openGallery}
                onClose={handleCloseGallery}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='car-gallery-modal'
            >
                <Box sx={style} className='main'>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Car Gallery
                    </Typography>
                    <CarGallery car={car} session={session} />
                </Box>
            </Modal >
        </>
    );
}
function PreviewCarAction(props) {
    const { car, setCar, style, row } = props;
    const [openView, setOpenView] = useState(false);
    const handleOpenView = () => setOpenView(true);
    const handleCloseView = () => setOpenView(false);

    const viewEvent = (e, row) => {
        e.stopPropagation();
        handleOpenView();
        setCar(row);
    }

    return (
        <>
            <Tooltip title="View Car" placement="top" >
                <IconButton className='car-action-btn' onClick={(e) => viewEvent(e, row)} >
                    <VisibilityIcon sx={{ color: common.black }} />
                </IconButton>
            </Tooltip>
            <Modal
                open={openView}
                onClose={handleCloseView}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='view-car-modal'
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        View Car
                    </Typography>
                    <ViewCar car={car} />
                </Box>
            </Modal >
        </>
    )

}