import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MdOutlineDone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { thousandSeperator } from '../utils/Format';
import CarImages from './CarImages';

const BidConfirmation = ({ car_id, bid_amount }) => {
    const [carDetails, setCarDetails] = useState({})
    let imgSrc = <CarImages car_id={car_id} />

    useEffect(() => {
        loadCarDetails()
    }, [])

    const loadCarDetails = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/cars/car/${car_id}`)
            .then(res => {
                if (typeof res.data !== 'string') {
                    // console.log(res.data);
                    setCarDetails(res.data)
                } else {
                    alert(res.data);
                }
            }).catch(err => {
                console.log(err.response.data);
            });
    };


    return (
        <div className="bid-success-container">
            <h2>Confirmation</h2>

            {carDetails?.img
                &&
                <img src={carDetails?.img} className='full-width-img' alt='car image' />
            }

            <section className="car-details-container">
                <h2 className="car-title">{carDetails?.model}</h2>
                <h3 className="car-subtitle">Regn No: {carDetails.car_reg}</h3>

                <hr />

                <div className="bid-success-data">
                    <span className="data-item">Bid Status</span>
                    <span className="data-value success"><MdOutlineDone /> Bid Successful</span>
                </div>

                <div className="bid-success-data">
                    <span className="data-item">Bidding Price</span>
                    <span className="data-value">${thousandSeperator(parseInt(bid_amount))}</span>
                </div>
            </section>

            <Link to='/biddings' className='full-width-button'>View All Biddings</Link>
        </div>
    )
}

export default BidConfirmation