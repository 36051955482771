import { Box, Card, CardContent, CardMedia, CssBaseline, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserPage } from "../components";
import Legend from "../components/Legend";
import Loading from "../components/Loading";
import { getUser } from "../utils/AuthSession";
import { thousandSeperator } from "../utils/Format";

export default function DealerBiddingsPage({ socket }) {
    const [biddings, setBiddings] = useState([]);
    const [bidsStatus, setBidsStatus] = useState([])
    const navigate = useNavigate();
    const [loadingBids, setLoadingBids] = useState(true);
    // const [refreshStatus, setRefreshStatus] = useState(0)

    useEffect(() => {
        socket.on('Bid Made', data => {
            getBidsStatus()
            console.log('Bid Made envent triggered 1...!');
        });

        return () => {
            setBidsStatus([])
        }
    }, []);

    useEffect(() => {
        // console.log('updating bid status in dealer biddings');
        getBidsStatus();
    }, [])

    const getBidsStatus = () => {
        const user = getUser();
        axios.post(`${process.env.REACT_APP_API_URL}/bids/dealerbids`,
            {
                user_id: user.id,
            }
        ).then(res => {
            console.log('Updated Bid Status (DealersBiddingPage) - ', res.data);
            setBidsStatus(res.data);
        }).catch(err => {
            console.log(err.response.data);
        });
    }

    useEffect(() => {
        const getDealerBiddings = () => {
            setLoadingBids(true)
            const user = getUser();
            axios.post(`${process.env.REACT_APP_API_URL}/bids/dealerbids`,
                {
                    user_id: user.id,
                }
            ).then(res => {
                // console.log('res.data - ', res.data);
                setBiddings(res.data);
                setLoadingBids(false)
            }).catch(err => {
                console.log(err.response.data);
                setLoadingBids(false)
            });
        }
        getDealerBiddings();

        return () => setBiddings([]);
    }, [])

    const viewBid = (bid) => {
        navigate(`/viewbid/${bid.id}`);
    }

    return (
        <UserPage>
            {/* <p style={{ padding: '2rem', fontWeight: 'bold', background: 'white', position: 'fixed', top: 0, left: 0, zIndex: 100 }}>{JSON.stringify(refreshStatus)}</p> */}

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <CssBaseline />
                <Box>
                    <section>
                        <h1 className="section-title">Biddings</h1>
                        {loadingBids
                            ? <Loading top='20' />
                            :
                            <>
                                <Grid className="car-list-container" container>
                                    {biddings.length > 0
                                        ?
                                        <>
                                            <Legend />
                                            <div className="bidding-list">
                                                {
                                                    biddings.map((bid, i) => {
                                                        return (
                                                            <div className="car-card bid-card bidding-list-card" key={i} onClick={() => navigate(`/viewcar/${bid[Object.keys(bid)[0]][0].car_id}`)}>
                                                                {/* {console.log('carId - ',bid[Object.keys(bid)[0]][0].car_id)} */}
                                                                {/* <div className="car-img" style={{ backgroundImage: `url('${bid[Object.keys(bid)[0]][0].img}')` }}>
                                                                </div> */}
                                                                <div className="car-data-container" >
                                                                    <div className="car-card-header">
                                                                        {bid[Object.keys(bid)[0]].length === 2
                                                                            ?
                                                                            <>
                                                                                <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>
                                                                                    {bid[Object.keys(bid)[0]][0].car_or_body}
                                                                                </span>
                                                                                <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][1].bid_status}`}>
                                                                                    {bid[Object.keys(bid)[0]][1].car_or_body}
                                                                                </span>
                                                                            </>

                                                                            :
                                                                            <>
                                                                                <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>
                                                                                    {bid[Object.keys(bid)[0]][0].car_or_body}
                                                                                </span>
                                                                            </>

                                                                        }

                                                                    </div>
                                                                    <div className="car-card-body">
                                                                        <Typography className="model">{bid[Object.keys(bid)[0]][0].model}</Typography>
                                                                        <Typography className="reg-number">Regn No: {bid[Object.keys(bid)[0]][0].car_reg}</Typography>
                                                                    </div>
                                                                    <div className="car-card-footer">
                                                                        {bid[Object.keys(bid)[0]].length === 2
                                                                            ?
                                                                            <>
                                                                                <span className={`bid-value ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_amount}`)}</span>
                                                                                <span className={`bid-value ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][1].bid_status}`}>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][1].bid_amount}`)}</span>
                                                                            </>

                                                                            :
                                                                            <>
                                                                                <span className={`bid-value ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_amount}`)}</span>
                                                                            </>

                                                                        }
                                                                    </div>
                                                                    <div className="car-card-footer-sm">
                                                                        {bid[Object.keys(bid)[0]].length === 2
                                                                            ?
                                                                            <>
                                                                                <div className="footer-item">
                                                                                    <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>
                                                                                        {bid[Object.keys(bid)[0]][0].car_or_body}
                                                                                    </span>
                                                                                    <p>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_amount}`)}</p>
                                                                                </div>
                                                                                <div className="footer-item">
                                                                                    <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][1].bid_status}`}>
                                                                                        {bid[Object.keys(bid)[0]][1].car_or_body}
                                                                                    </span>
                                                                                    <p>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][1].bid_amount}`)}</p>
                                                                                </div>
                                                                            </>

                                                                            :
                                                                            <>
                                                                                <div className="footer-item">
                                                                                    <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>
                                                                                        {bid[Object.keys(bid)[0]][0].car_or_body}
                                                                                    </span>
                                                                                    <p>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_amount}`)}</p>
                                                                                </div>
                                                                            </>

                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            // <div className="car-card bid-card" key={i} onClick={() => navigate(`/viewcar/${bid[Object.keys(bid)[0]][0].car_id}`)}>
                                                            //     {/* {console.log('carId - ',bid[Object.keys(bid)[0]][0].car_id)} */}
                                                            //     <div className="car-img" style={{ backgroundImage: `url('${bid[Object.keys(bid)[0]][0].img}')` }}>
                                                            //     </div>
                                                            //     <div className="car-data-container">
                                                            //         <div className="car-card-header">
                                                            //             {bid[Object.keys(bid)[0]].length === 2
                                                            //                 ?
                                                            //                 <>
                                                            //                     <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>
                                                            //                         {bid[Object.keys(bid)[0]][0].car_or_body}
                                                            //                     </span>
                                                            //                     <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][1].bid_status}`}>
                                                            //                         {bid[Object.keys(bid)[0]][1].car_or_body}
                                                            //                     </span>
                                                            //                 </>

                                                            //                 :
                                                            //                 <>
                                                            //                     <span className={`bid-type ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>
                                                            //                         {bid[Object.keys(bid)[0]][0].car_or_body}
                                                            //                     </span>
                                                            //                 </>

                                                            //             }

                                                            //         </div>
                                                            //         <div>
                                                            //             <Typography className="model">{bid[Object.keys(bid)[0]][0].model}</Typography>
                                                            //         </div>
                                                            //         <div>
                                                            //             <Typography className="reg-number">Regn No: {bid[Object.keys(bid)[0]][0].car_reg}</Typography>
                                                            //         </div>
                                                            //         <div className="car-card-footer">
                                                            //             {bid[Object.keys(bid)[0]].length === 2
                                                            //                 ?
                                                            //                 <>
                                                            //                     <span className={`bid-value ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_amount}`)}</span>
                                                            //                     <span className={`bid-value ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][1].bid_status}`}>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][1].bid_amount}`)}</span>
                                                            //                 </>

                                                            //                 :
                                                            //                 <>
                                                            //                     <span className={`bid-value ${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_status}`}>${thousandSeperator(`${bidsStatus.length && bidsStatus[i][Object.keys(bidsStatus[i])[0]][0].bid_amount}`)}</span>
                                                            //                 </>

                                                            //             }
                                                            //         </div>
                                                            //     </div>
                                                            // </div>


                                                        );
                                                    })
                                                }

                                            </div>
                                        </>
                                        : <Typography className="text-center">You don't have any biddings</Typography>
                                    }
                                </Grid>
                            </>
                        }
                    </section>
                </Box>
            </Container>
        </UserPage>
    );
}
