import { ChangePasswordForm } from "../components";
import { Navigate } from "react-router-dom";
import { Container } from "@mui/system";
import { CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import axios from 'axios'
import { getUser } from "../utils/AuthSession";

export default function AccountPage() {
    const [userData, setUserData] = useState('')
    
    const {id, username} = getUser()

    useEffect(() => {
        fetchUserData()
    }, [])

    const fetchUserData = () => {

        axios.post(`${process.env.REACT_APP_API_URL}/dealers/dealerDetails`, { user_id: id })
            .then(res => {
                setUserData(res.data)
            })
            .catch(err => {
                alert(err.response.data)
            })

    }

    if (sessionStorage.getItem("LoggedUser")) {
        return (
            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <h1 className="section-title">Account</h1>
                <CssBaseline />
                <section className="car-details-container">
                    <div className='car-info-table'>
                        <div className='table-row'><h3 className="table-title">Your Information</h3></div>

                        {userData
                            ?
                            <>
                                <div className='table-row'>
                                    <div className='table-data-title'>Company Name</div>
                                    <div className='table-data'>{userData.name}</div>
                                </div>
                                <div className='table-row'>
                                    <div className='table-data-title'>Username</div>
                                    <div className='table-data'>{username}</div>
                                </div>
                                <div className='table-row'>
                                    <div className='table-data-title'>Email</div>
                                    <div className='table-data'>{userData.email}</div>
                                </div>
                            </>

                            :
                            <p>Loading...</p>
                        }

                    </div>
                </section>
            </Container>

        );
    } else {
        return (<Navigate to='/login' />);
    }
}