import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import { Alert, ButtonGroup } from '@mui/material';
import common from "@mui/material/colors/common";
import red from "@mui/material/colors/red";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { DataTable, TabPanel, CreateNewUserForm, AdminPage } from "../components";
import DealerPasswordChange from "./DealerPasswordChange";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { green } from "@mui/material/colors";
import { isFinance } from "../utils/AuthSession";
import axios from "axios";


export default function UserAccountsPage() {
    const [changingPwd, setChangingPwd] = useState(true);

    return (
        <AdminPage>
            <Container maxWidth="lg">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        User Accounts
                    </Typography>
                    <Container className='tab-panel-container' maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid className='tab-panel-container' container spacing={3}>
                            <Grid className='tab-panel-container' item xs={12}>
                                <Box className='tab-panel-container' sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <UserAccountsPageTabs />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Container >
        </AdminPage >
    );
}

function UserAccountsPageTabs() {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box className='tab-container' sx={{ width: '100%', bgcolor: 'transparent' }}>
            <Tabs className='tabs' value={value} onChange={handleChange} centered>
                <Tab className='tab' label="Dealers" />
                <Tab className='tab' label="New Dealer" />
            </Tabs>

            <TabPanel value={value} index={0}>
                <UserAccounts />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CreateNewUserForm />
            </TabPanel>
        </Box>);
}

function UserAccounts() {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate()
    const columns = [
        {
            field: 'id', headerName: 'ID',
            headerAlign: 'center',
            align: 'center', maxWidth: 100
        },
        {
            field: 'name', headerName: 'Name',
            headerAlign: 'center',
            align: 'center', minWidth: 130
        },
        {
            field: 'company_name', headerName: 'Company Name',
            headerAlign: 'center',
            align: 'center', minWidth: 130
        },
        {
            field: 'username', headerName: 'Username',
            headerAlign: 'center',
            align: 'center', minWidth: 130
        },
        {
            field: 'email', headerName: 'Email',
            headerAlign: 'center',
            align: 'center', minWidth: 130
        },
        {
            field: 'modified', headerName: 'Modified', type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            headerAlign: 'center',
            align: 'center',
            minWidth: 170
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const deleteEvent = (e) => {
                    e.stopPropagation();
                    if (window.confirm(`Deleting User ${params.row.name} will delete ${params.row.name} Profile, Login, Bids and Details`)) {
                        deleteUser(params.row.id);
                    }
                };

                const changePasswordEvent = (e) => {
                    e.stopPropagation();
                    navigate(`/useraccounts/changePassword/${params.row.id}`)

                };
                return (
                    <>
                        <Tooltip title="Change Password" placement="top">
                            <Button onClick={() => navigate(`/useraccounts/changePassword/${params.row.id}`)}>
                                <LockResetOutlinedIcon sx={{ background: green[500], color: '#fff', p: 0.4, borderRadius: '2px', fontSize: '27px' }} />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Delete User" placement="top">
                            <Button onClick={deleteEvent}>
                                <DeleteOutlineIcon sx={{ background: red[500], color: '#fff', p: 0.4, borderRadius: '2px', fontSize: '27px' }} />
                            </Button>
                        </Tooltip>
                    </>);
            },
            minWidth: 170
        },
    ];
    const getDealers = () => {
        const user = JSON.parse(sessionStorage.getItem("LoggedUser"));
        axios.post(`${process.env.REACT_APP_API_URL}/admins/dealers`,
            {
                username: user.username,
                user_id: user.id
            }
        )
            .then(res => {
                if (res.data && res.data.length > 0 && typeof res.data !== 'string') {
                    setUsers(res.data)
                }
            }).catch(error => {
                console.log(error);
            });
    }
    const deleteUser = (delete_user_id) => {
        const user = JSON.parse(sessionStorage.getItem("LoggedUser"));
        axios.post(`${process.env.REACT_APP_API_URL}/admins/removedealer`,
            {
                username: user.username,
                user_id: user.id,
                delete_user_id: delete_user_id
            }

        )
        .then(res => {
            alert(res.data);
            getDealers();
        }).catch(error => {
            console.log(error);
        });
    }
    const changeUserPassword = () => { console.log("changeUserPassword"); }

    // const rows = [
    //     { id: 1, name: 'Bob', username: 'test', role: 'Dealer', modified: '2022-10-10 15:55:50' },
    //     { id: 2, name: 'Admin', username: 'admin', role: 'Admin', modified: '2022-10-11 16:59:04' },
    // ];

    useEffect(() => {
        getDealers();
        return () => setUsers([]);
    }, []);

    return (<DataTable columns={columns} rows={users} pageSize={20} rowHeight={50} rowsPerPageOptions={[20]} />);
}

