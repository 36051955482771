
export const isUser = () => {
    const user = getUser();
    if (user) {
        return (Object.keys(user).length > 0);
    } else { return false; }
}
export const isAdmin = () => {
    const user = getUser();
    return (isUser() && user?.role === "admin");
}

export const isFinance = () => {
    const user = getUser();
    return (user?.is_finance ? true : false)
}
export const isAuditor = () => {
    const user = getUser();
    return (user?.role==='auditor' ? true : false)
}
export const isDealer = () => {
    const user = getUser();
    return (user?.role==='dealer' ? true : false)
}

export const getUser = () => {
    return JSON.parse(sessionStorage.getItem("LoggedUser"));
}

export const setUser = (user) => { // { id: data.id, name: data.name, role: data.role, username: user }
    console.log(user);
    sessionStorage.setItem("LoggedUser", JSON.stringify(user));
}
