import AWS from 'aws-sdk';

const albumBucketName = process.env.REACT_APP_AWS_ALBUM_BUCKET_NAME;
const bucketRegion = process.env.REACT_APP_AWS_BUCKET_REGION;
// const IdentityPoolId = "ap-southeast-1:d62cc88b-51d3-40af-8634-bfeafb59b880";

AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.Credentials({
        accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY
    })
});



export async function uploadFile(dir, file) {
    var upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: albumBucketName,
            Key: dir,
            Body: file
        }
    });
    var promise = upload.promise();

    return await promise.then(
        function (data) {
            return data;
        },
        function (err) {
            alert("There was an error uploading your photo: ", err.message);
            return false;
        }
    );
}

export async function listFiles(dir) {
    const s3 = new AWS.S3({
        apiVersion: process.env.REACT_APP_AWS_S3_API_VERSION,
        params: { Bucket: albumBucketName }
    });
    var promise = s3.listObjects({ Prefix: dir }).promise();
    return await promise.then(
        function (data) {
            return data.Contents;
        },
        function (err) {
            alert("There was an error uploading your photo: ", err.message);
            return false;
        }
    );
}

export async function deleteFile(dir) {
    const s3 = new AWS.S3({
        apiVersion: process.env.REACT_APP_AWS_S3_API_VERSION,
        params: { Bucket: albumBucketName }
    });

    var promise = s3.deleteObject({ Key: dir }).promise();
    return await promise.then(
        function (data) {
            return true;
        },
        function (err) {
            alert("There was an error deleting your photo: ", err.message);
            return false;
        }
    );
}
