import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Alert } from '@mui/material';
import axios from 'axios'
import { getUser } from "../utils/AuthSession";
import { useNavigate } from 'react-router-dom';

const ChangeEmailForm = () => {
    const [email, setEmail] = useState('')
    const [updatedEmail, setUpdatedEmail] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        fetchEmail()
    }, [])

    const fetchEmail = () => {
        const user_id = getUser().id

        axios.post(`${process.env.REACT_APP_API_URL}/dealers/dealerEmail`, { user_id })
            .then(res => {
                setEmail(res.data)
            })
            .catch(err => {
                alert(err.response.data)
            })

    }

    const handleSubmit = e => {
        e.preventDefault()
        setSubmitted(true)
        setSuccessMsg('')

        const user_id = getUser().id

        axios.post(`${process.env.REACT_APP_API_URL}/dealers/updateDealerEmail`, { email: updatedEmail, user_id, previousEmail:email })
            .then(res => {
                setSubmitted(false)
                setSuccessMsg(res.data)
                setTimeout(() => {
                    navigate('/account')
                }, 3000)
            })
            .catch(err => {
                alert(err.response.data)
                setSubmitted(false)
            })
    }



    return (
        <Container className='login-container' maxWidth="lg" sx={{ mt: 2 }}>
            <h1 className="section-title">Update Email</h1>
            {successMsg
                &&
                <p className='success-msg'>{successMsg}</p>
            }
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box className='login-form' component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="current_email"
                        label="Current Email"
                        name="current_email"
                        disabled={true}
                        value={email}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label="New Email"
                        type="email"
                        id="email"
                        value={updatedEmail}
                        onInput={e => setUpdatedEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={submitted || successMsg}
                    >
                        {submitted
                            ? 'Updating...'
                            : 'Update Email'
                        }
                    </Button>
                    <Grid container>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}

export default ChangeEmailForm