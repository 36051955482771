import { Box, Container, CssBaseline, TextField } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import logo from '../images/warnes-black.png'


const SendPasswordResetEmail = () => {
    const [username, setUsername] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const sendResetEmail = (e) => {
        e.preventDefault()
        setErrorMsg('')
        setSuccessMsg('')
        setLoading(true)

        if (username === '') {
            setErrorMsg('Please enter your username')
            setLoading(false)
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/auth/sendResetEmail`, { username })
                .then(res => {
                    setSuccessMsg(res.data)
                    setLoading(false)
                    setTimeout(() => {
                        window.location.replace('/login')
                    }, 3000)
                })
                .catch(error => {
                    setErrorMsg(error.response.data)
                    setLoading(false)
                });
        }
    }
    return (
        <Container className='login-container' component="main">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
                <img className='logo' src={logo} />
                {/* </Avatar> */}
                <h1 className='title'>Reset Password</h1>
                <p className='subtitle' style={{ marginBottom: '2rem' }}>Please add your username to get Password Reset Link on your email.</p>
                {errorMsg
                    && <p className='error-msg'>{errorMsg}</p>
                }
                {successMsg
                    && <p className='success-msg'>{successMsg}</p>
                }
                <Box className='login-form' component="form" onSubmit={sendResetEmail} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required={true}
                        id="username"
                        label="Enter Username"
                        name="username"
                        autoFocus
                        value={username}
                        onInput={e => { setErrorMsg(''); setSuccessMsg(''); setUsername(e.target.value) }}
                    />
                    <button
                        type="submit"
                        disabled={loading}
                    >
                        {loading
                            ? 'Sending...'
                            : 'Send Email'
                        }
                    </button>
                </Box>
            </Box>
        </Container>
    )
}

export default SendPasswordResetEmail