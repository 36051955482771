import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Alert } from '@mui/material';
import axios from 'axios'
import { getUser } from '../utils/AuthSession';

export default class ChangePasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: JSON.parse(sessionStorage.getItem("LoggedUser")).username,
            old_password: '',
            new_password: '',
            confirm_password: '',
            submitted: false,
            valid: false,
            matched: false,
            errorMsg: '',
            successMsg: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            this.validateForm();
        });
    }
    handleSubmit(event) {
        console.log("handleSubmit");
        this.setState({ submitted: true });
        event.preventDefault();
        this.changePassword(this.state.username, this.state.old_password, this.state.new_password);
    }

    validateForm() {
        console.log("validateForm");
        // browser take care of empty fields

        this.setState({
            valid:
                (
                    this.state.old_password !== '' &&
                    this.state.new_password !== '' &&
                    this.state.confirm_password !== '' &&
                    (this.state.new_password === this.state.confirm_password)
                )
        });
        console.log(this.state.error);
    }

    async changePassword(user, pass, npass) {

        this.setState({errorMsg: '', successMsg:''})

        axios.post(`${process.env.REACT_APP_API_URL}/auth/changePass`,
            { username: user, old_password: pass, new_password: npass, role: getUser().role, user_id:getUser().id }
        )
            .then(res => {
                this.setState({ successMsg: res.data })
                this.setState({ submitted: false })
                setTimeout(() => {
                    window.location.replace('/account')
                }, 3000)
            })
            .catch(err => {
                this.setState({ errorMsg: err.response.data })
                this.setState({ submitted: false })
            })
    }


    render() {
        return (
            <Container className='login-container' maxWidth="lg" sx={{ mt: 2 }}>
                <h1 className="section-title">Update Password</h1>
                <CssBaseline />
                {this.state.errorMsg
                    &&
                    <p className='error-msg'>{this.state.errorMsg}</p>
                }
                {this.state.successMsg
                    &&
                    <p className='success-msg'>{this.state.successMsg}</p>
                }
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box className='login-form' component="form" onSubmit={this.handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            disabled={true}
                            value={this.state.username}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="old_password"
                            label="Current Password"
                            type="password"
                            id="old_password"
                            onInput={this.handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="new_password"
                            label="New Password"
                            type="password"
                            id="new_password"
                            onInput={this.handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirm_password"
                            label="Re-enter new Password"
                            type="password"
                            id="confirm_password"
                            onInput={this.handleChange}
                        />
                        {(this.state.confirm_password !== '' && this.state.new_password !== '' && this.state.confirm_password !== this.state.new_password) && <Alert severity="error">Passwords don't match</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={this.state.submitted || this.state.new_password !== this.state.confirm_password}
                        >
                            Update Password
                        </Button>
                        <Grid container>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        );
    }
}