import { Box, Container, CssBaseline } from "@mui/material";
import { UserPage } from "../components";
import { getUser } from "../utils/AuthSession";

export default function ProfilePage() {
    const user = getUser()
    return (
        <UserPage>
            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <CssBaseline />
                <Box>
                    {user?.name} the {user?.role}
                </Box>
                <Box>
                    Stats
                </Box>
            </Container>
        </UserPage>
    );
}
