import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CarDetails, CarImages, UserPage } from "../components";
import { red, orange, green, common } from "@mui/material/colors";
import { getUser } from "../utils/AuthSession";
import Message from "../components/Message";
import Legend from "../components/Legend";
import BackButton from "../components/BackButton";
import { thousandSeperator } from "../utils/Format";
import axios from "axios";
import ImageCarousel from "../components/ImageCarousel";

export default function ViewDealerBidPage() {
    const { car_id } = useParams();
    return (
        <UserPage>
            <Container maxWidth="lg" sx={{ mt: 2 }}>
                {car_id && <BidDetails car_id={car_id} />}
            </Container>
        </UserPage>
    );
}

function BidDetails(props) {
    const navigate = useNavigate();
    const { car_id } = props;
    const [bid, setBid] = useState([]);

    let img = [];
    if(bid[0]?.img){
        img = [{imgPath: bid[0].img}]
    }
    useEffect(() => {
        const getBid = () => {
            axios.post(`${process.env.REACT_APP_API_URL}/bids/bid/${car_id}`,
                { user_id: getUser().id }
            )
                .then(res => {
                    console.log('res.data - ', res.data[0][Object.keys(res.data[0])[0]]);
                    if (typeof res.data !== 'string') {
                        setBid(res.data[0][Object.keys(res.data[0])[0]]);
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
        getBid();
        return () => setBid(null);
    }, [car_id])
    return (
        <UserPage>
            {/* <h1 className="section-title">&nbsp;</h1> */}
            {bid.length
                ?
                <>
                    {car_id &&
                        <>
                            {/* <Box className="page-header-img" style={{ backgroundImage: `url('${bid[0].img}')` }}>
                            </Box> */}
                            <ImageCarousel images={img} />
                            <Box>
                                <CarDetails car_id={car_id} backBtn={true} />
                            </Box>
                        </>
                    }
                    <Box>
                        <section className="car-details-container">
                            <div className='car-info-table'>
                                <div className='table-row'><h3 className="table-title">Bid Status</h3></div>
                                {bid.length === 0
                                    ?
                                    <p>No Bids</p>

                                    :
                                    <>
                                        {bid.map((item, i) => {
                                            return (
                                                <div className='table-row' key={i}>
                                                    <div className='table-data-title'>{item.car_or_body}</div>
                                                    <div className='table-data'><span className={`bid-value ${item.bid_status}`}>${thousandSeperator(item.bid_amount)}</span></div>
                                                </div>
                                            )
                                        })}
                                        <Legend />
                                    </>
                                }
                            </div>
                        </section>
                    </Box>
                </>

                :
                <Message message='No Car Found' type='danger' />
            }
        </UserPage >
    );
};
