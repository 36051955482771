import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React from "react";
import { Link, Navigate } from 'react-router-dom';
import { setUser } from '../utils/AuthSession';
import logo from '../images/warnes-black.png'
import { width } from '@mui/system';
import axios from 'axios'
import { Backdrop, Fade, Modal } from '@mui/material';
import ForcedLoggedOutModal from '../components/ForcedLoggedOutModal';

export default function LoginPage() {
    if (sessionStorage.getItem("LoggedUser")) {
        return (<Navigate to='/' />);
    } else {
        return (
            <LoginForm />
        );
    }
}
class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            error: '',
            open: false,
            style: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                boxShadow: 24,
                p: 4,
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }


    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        this.setState({ submitted: true });
        event.preventDefault();
        this.login(this.state.username, this.state.password);
    }

    handleClose() {
        this.setState({ open: false })
    }

    handleOpen() {
        this.setState({ open: true })
    }

    async login(user, pass) {

        this.setState({ error: '' })

        axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { username: user, password: pass }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                console.log(res);
                if (res.data.active_sessions === 0 && res.data.role === 'dealer') {
                    //show modal saying cant login
                    this.setState({ open: true })
                } else {
                    if (res.data.role === 'admin') {
                        let finance = true;
                        if (res.data.is_finance === 0) {
                            finance = false
                        }
                        setUser({ id: res.data.id, name: res.data.name, role: res.data.role, is_finance: finance, username: user });
                    } else {
                        setUser({ id: res.data.id, name: res.data.name, role: res.data.role, username: user });
                    }
                    window.location = '/'
                }
                this.setState({ submitted: false });
            })
            .catch(error => {
                console.log(error)
                this.setState({ error: error.response.data })
                this.setState({ submitted: false });
            });
    }

    render() {
        return (
            <Container className='login-container' component="main">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
                    <img className='logo' src={logo} />
                    {/* </Avatar> */}
                    <h1 className='title'>Log In Now</h1>
                    <p className='subtitle'>Please login to continue using our app</p>
                    <p className='error-msg'>{this.state.error}</p>
                    <Box className='login-form' component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            id="username"
                            label="Username"
                            name="username"
                            autoFocus
                            onInput={this.handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onInput={this.handleChange}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <button
                            type="submit"
                            // fullWidth
                            // variant="contained"
                            // sx={{ mt: 3, mb: 2 }}
                            disabled={this.state.submitted}
                        >
                            Log In
                        </button>
                        <Typography sx={{ textAlign: 'center', mt: 2 }}><Link to='/reset-password' style={{ color: '#00454c', textDecoration: 'underline' }}>Forgot Password?</Link></Typography>

                        <Grid container>
                            {/* <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid> */}
                            {/* <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Box>
                {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    className='confirmation-modal'
                >
                    <Fade in={this.state.open}>
                        <Box sx={this.state.style}>
                            <h3>No Active Bid Session</h3>
                            <hr className='hr'></hr>
                            <p>There aren't any bid sessions active at the moment. Please try to log in again later.</p>
                            {/* <button className='btn primary-btn' onClick={this.handleClose}>Back</button> */}
                        </Box>
                    </Fade>
                </Modal>
            </Container>


        );
    }
}
// function Copyright(props) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'Copyright © '}
//             <Link color="inherit" href="https://mui.com/">
//                 Your Website
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }
