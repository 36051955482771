const PageNotFound = () => {
    return (
        <div>
            <h1>404 Page Not Found</h1>
            <p>The page you are looking for does not exists. Please recheck the URL</p>
        </div>
    );
}

export default PageNotFound

// if (sessionStorage.getItem("LoggedUser")) {
//     if (JSON.parse(sessionStorage.getItem("LoggedUser")).role === "Admin") {
//         // Admin Component
//     } else {
//         return (<Navigate to='/' />);
//     }
// } else {
//     return (<Navigate to='/login' />);
// }