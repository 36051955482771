import { Card, CardContent, CardMedia, Container, CssBaseline, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { UserPage } from "../components";
import { useNavigate } from "react-router-dom";
import { getUser } from "../utils/AuthSession";
import axios from "axios";
import Loading from "../components/Loading";
export default function HomePage() {
    let userRole = getUser()?.role

    return (
        <UserPage>
            {/* {userRole !== 'admin'
                ?
                <AuctionList />
                :
                <UserAccountsPage />
            } */}
            <AuctionList />
        </UserPage>
    );
}

function AuctionList() {
    const [cars, setCars] = useState([]);
    const navigate = useNavigate();
    const [loadingAuctionList, setLoadingAuctionList] = useState(true);

    const viewCar = (car) => {
        navigate(`/viewcar/${car.id}`);
    }

    useEffect(() => {
        setLoadingAuctionList(true)
        const getAuctionList = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/cars/auctionlist`)
                .then(res => {
                    if (res.data && typeof res.data !== 'string') {
                        setCars(res.data)
                        setLoadingAuctionList(false)
                    } else {
                        alert(res.data);
                        setLoadingAuctionList(false)
                    }
                }).catch(err => {
                    console.log(err.response.data);
                    setLoadingAuctionList(false)
                });
        };
        getAuctionList();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 2 }}>
            <h1 className="section-title">Auction</h1>
            <CssBaseline />
            <>
                {loadingAuctionList
                    ? <Loading />

                    : <Grid className="car-list-container" container>
                        {cars.length > 0
                            ? cars.map((car, i) => {
                                return (
                                    <div className="car-card" key={car.id} onClick={() => viewCar(car)}>
                                        <div className="car-img" style={{ backgroundImage: `url('${car.img}')` }}>
                                            {/* {car.img && <img className="car-img" alt={car.model} src={car.img} />} */}
                                        </div>
                                        <div className="car-data-container">

                                            <div>
                                                <Typography className="model">{car.model}</Typography>
                                            </div>
                                            <div>
                                                <Typography className="reg-number">Regn No: {car.car_reg}</Typography>
                                            </div>

                                            {/* <div className="value-container">
                                    <div className="value">
                                        <Typography className="value-title">Whole Car</Typography>
                                        <Typography className="value-data">${thousandSeperator(car.minbidvalue_wholecar)} Onwards</Typography>
                                    </div>
                                    <div className="value">
                                        <Typography className="value-title">Body Only</Typography>
                                        <Typography className="value-data">${thousandSeperator(car.minbidvalue_bodyonly)} Onwards</Typography>
                                    </div>
                                </div> */}

                                        </div>
                                    </div>
                                );
                            })
                            : <Typography>No Cars Available</Typography>
                        }
                    </Grid>
                }
            </>
        </Container>
    )
}
