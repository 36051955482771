import { Box, Container, CssBaseline, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import logo from '../images/warnes-black.png'
import { setUser } from '../utils/AuthSession'
import PageNotFound from './PageNotFound'
import Message from '../components/Message'

const ResetPassword = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [isResetSuccess, setIsResetSuccess] = useState(false);
    const [loading, setLoading] = useState(false)
    const [authenticating, setAuthenicating] = useState(true);
    const { resetCode } = useParams()
    const [isValidCode, setIsValidCode] = useState(false)
    const [userId, setUserId] = useState()
    const [isExpired, setIsExpired] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        checkCode()
    }, [])


    const checkCode = () => {
        setAuthenicating(true)
        axios.post(`${process.env.REACT_APP_API_URL}/auth/matchResetCode`, { resetCode })
            .then(res => {
                if (res.data.length > 0) {
                    if ((new Date() - new Date(res.data[0].password_reset_code_dateTime)) > 3000000) {
                        setIsExpired(true)
                        setAuthenicating(false)
                    } else {
                        setIsExpired(false)
                        setIsValidCode(true)
                        setUserId(res.data[0].user_id)
                        setAuthenicating(false)
                    }

                } else {
                    setIsValidCode(false)
                    setAuthenicating(false)
                }
            })
            .catch(error => {
                console.log(error.res.data)
                setAuthenicating(false)
            });
    }

    const resetPassword = (e) => {
        e.preventDefault()
        setErrorMsg('')
        setSuccessMsg('')
        setIsResetSuccess(false)
        setLoading(true)

        if (password !== confirmPassword) {
            setErrorMsg('Please add same passwords in both fields')
            setLoading(false)
        }
        else {
            console.log(userId, ' - ', password)
            axios.post(`${process.env.REACT_APP_API_URL}/auth/resetPassword`, { user_id: userId, password })
                .then(res => {
                    setSuccessMsg(res.data)
                    setIsResetSuccess(true)
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error);
                    setErrorMsg(error.response.data)
                    setLoading(false)
                });
        }

    }
    return (
        <>
            {authenticating

                ? <Message message='Loading...' />

                :
                <>
                    {!isValidCode
                        ? <PageNotFound />

                        :
                        <Container className='login-container' component="main">
                            <CssBaseline />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                {isExpired
                                    ? <Message message='Your Password Reset Link has expired. Please try to reset password again.' type='danger' />
                                    :
                                    <>

                                        <img className='logo' src={logo} />
                                        <h1 className='title'>Reset Password</h1>
                                        <p className='subtitle' style={{ marginBottom: '2rem' }}>Please create a new password.</p>
                                        {errorMsg
                                            && <p className='error-msg'>{errorMsg}</p>
                                        }
                                        <Box className='login-form' component="form" onSubmit={resetPassword} noValidate sx={{ mt: 1 }}>
                                            {!isResetSuccess
                                                ? <>
                                                    <TextField
                                                        margin="normal"
                                                        required={true}
                                                        type='password'
                                                        id="new-password"
                                                        label="New Password"
                                                        name="new-password"
                                                        autoFocus
                                                        value={password}
                                                        onInput={e => { setErrorMsg(''); setSuccessMsg(''); setPassword(e.target.value) }}
                                                    />
                                                    <TextField
                                                        margin="normal"
                                                        required={true}
                                                        type='password'
                                                        id="confirm-new-password"
                                                        label="Confirm New Password"
                                                        name="confirm-new-password"
                                                        value={confirmPassword}
                                                        onInput={e => { setErrorMsg(''); setSuccessMsg(''); setConfirmPassword(e.target.value) }}
                                                    />
                                                    <button
                                                        type="submit"
                                                        disabled={loading || successMsg}
                                                    >
                                                        Reset
                                                    </button>
                                                </>

                                                : <>
                                                    <p className='success' style={{textAlign:'center', padding:'0 2vw'}}>Password have been successfully reset. You can now login to your account with new password.</p>
                                                    <button
                                                        type="submit"
                                                        onClick={() => navigate('/login')}
                                                        style={{margin:'auto', marginTop:'1.3rem'}}
                                                    >
                                                        Login
                                                    </button>
                                                </>
                                            }
                                        </Box>
                                    </>
                                }
                            </Box>
                        </Container>
                    }
                </>
            }
        </>
    )
}

export default ResetPassword