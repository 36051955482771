import { Box } from '@mui/system'
import React from 'react'

const Message = ({message, type}) => {
  const style = {
    width: '100%',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }

  return (
    <Box className={`msg ${type}`} sx={style}>{message}</Box>
  )
}

export default Message