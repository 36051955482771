import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { AdminPage, DataTable } from "../components";
import { Box, Button, Grid, Modal, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { FcAddRow } from "react-icons/fc";

export default function PaymentMethods() {
    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentMethodData, setPaymentMethodData] = useState({ methodName: "", paymentMethod: "" })
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

    const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true)
    const [creatingPaymentMethod, setCreatingPaymentMethod] = useState(false)
    const [updatingPaymentMethod, setUpdatingPaymentMethod] = useState(false)
    const [deletingPaymentMethod, setDeletingPaymentMethod] = useState(false)

    const [createModalState, setCreateModalState] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [updateModalState, setUpdateModalState] = useState(false)

    useEffect(() => {
        if (createModalState) {
            setPaymentMethodData({ methodName: "", paymentMethod: "" })
        }
    }, [createModalState])

    let columns = [
        {
            field: 'index', headerName: 'ID',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.id
            }
        },
        {
            field: 'method_name', headerName: 'Name',
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'payment_method', headerName: 'Payment Method',
            headerAlign: 'center',
            align: 'center',
            minWidth: "500",
            sortable: false,
            renderCell: (params) => {
                return <span style={{ whiteSpace: "pre", padding: "10px 0" }}>{params.value}</span>
            }
        },
        {
            field: 'id', headerName: 'Actions',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            flex: 1,
            minWidth: 250,
            renderCell: (params) => {
                return (
                    <div style={{ display: "flex", }}>
                        <Tooltip title="Delete payment method" placement="left">
                            <Button onClick={() => { setSelectedPaymentMethod(paymentMethods.filter(method => method.id === params.value)[0]); setDeleteModalState(true) }}>
                                <FaTrash style={{ fill: "#222" }} />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Edit payment method" placement="left">
                            <Button onClick={() => { const filtered = paymentMethods.filter(method => method.id === params.value)[0]; setSelectedPaymentMethod(filtered); setPaymentMethodData({ methodName: filtered.method_name, paymentMethod: filtered.payment_method }); setUpdateModalState(true) }}>
                                <FaEdit style={{ fill: "#222" }} />
                            </Button>
                        </Tooltip>
                    </div>
                )
            }
        },
    ]

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {
        getPaymentMethods()
    }, [])

    const getPaymentMethods = () => {
        setLoadingPaymentMethods(true)
        axios.get(`${process.env.REACT_APP_API_URL}/admins/paymentMethods/all`)
            .then(res => {
                if (res.data) {
                    setPaymentMethods(res.data)
                }
                setLoadingPaymentMethods(false)
            }).catch(error => {
                alert("Could not get payment methods at the moment!")
                console.log(error);
                setLoadingPaymentMethods(false)
            });
    }


    const createPaymentMethod = (e) => {
        e.preventDefault()
        if (paymentMethodData.methodName && paymentMethodData.paymentMethod) {
            setCreatingPaymentMethod(true)
            axios.post(`${process.env.REACT_APP_API_URL}/admins/paymentMethod/create`, { payment_method: paymentMethodData.paymentMethod, method_name: paymentMethodData.methodName })
                .then(res => {
                    if (res.data) {
                        setPaymentMethods(prev => {
                            const updated = [...prev]
                            updated.push({ id: res.data.inserted_payment_method_id, method_name: paymentMethodData.methodName, payment_method: paymentMethodData.paymentMethod })
                            return updated
                        })

                        setPaymentMethodData({ methodName: "", paymentMethod: "" })
                        setCreateModalState(false)
                    }
                    setCreatingPaymentMethod(false)
                }).catch(error => {
                    alert("Could not create payment methods at the moment!")
                    console.log(error);
                    setCreatingPaymentMethod(false)
                });
        }
    }


    const deletePaymentMethod = () => {
        setDeletingPaymentMethod(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/admins/paymentMethod/${selectedPaymentMethod?.id}`)
            .then(res => {
                if (res.data) {
                    setSelectedPaymentMethod(null)
                    setDeleteModalState(false)
                    setPaymentMethods(prev => {
                        let updated = [...prev]
                        updated = updated.filter(method => method.id !== res.data.deleted_payment_method_id)
                        return updated
                    })
                }
                setDeletingPaymentMethod(false)
            }).catch(error => {
                if (error.response?.data?.error) {
                    alert(error.response.data.error)
                } else {
                    alert("Could not delete payment method at the moment!")
                }
                console.log(error);
                setDeletingPaymentMethod(false)
            });
    }


    const updatePaymentMethod = (e) => {
        e.preventDefault()
        if (selectedPaymentMethod?.id && paymentMethodData.methodName && paymentMethodData.paymentMethod) {
            setUpdatingPaymentMethod(true)
            axios.post(`${process.env.REACT_APP_API_URL}/admins/paymentMethod/update`, { id: selectedPaymentMethod?.id, updated_payment_method: paymentMethodData.paymentMethod, updated_method_name: paymentMethodData.methodName })
                .then(res => {
                    if (res.data) {
                        setPaymentMethods(prev => {
                            const updated = [...prev]
                            const index = updated.findIndex(method => method.id === selectedPaymentMethod?.id)
                            updated[index] = { ...updated[index], payment_method: res.data.payment_method, method_name: res.data.method_name }
                            return updated
                        })

                        setPaymentMethodData({ methodName: "", paymentMethod: "" })
                        setUpdateModalState(false)
                    }
                    setUpdatingPaymentMethod(false)
                }).catch(error => {
                    alert("Could not update payment methods at the moment!")
                    console.log(error);
                    setUpdatingPaymentMethod(false)
                });
        }
    }


    return (
        <AdminPage>
            <Container maxWidth="lg">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Payment Methods
                        </Typography>

                        <Tooltip title="New Payment Method" placement="left" sx={{ width: "auto" }}>
                            <Button onClick={() => { setCreateModalState(true) }}>
                                <FcAddRow style={{ fill: "#222", fontSize: "2rem" }} />
                            </Button>
                        </Tooltip>
                    </Box>

                    <Container className='tab-panel-container' maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <DataTable rows={paymentMethods} columns={columns} rowHeight={50} pageSize={10} style={{ height: '550px' }} getRowHeight={() => 'auto'} />
                    </Container>
                </Box>
            </Container >


            {/* Create Payment Method */}
            <Modal
                open={createModalState}
                onClose={() => setCreateModalState(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: '2rem' }}>
                        New Payment Method
                    </Typography>

                    <form onSubmit={createPaymentMethod}>
                        <div style={{ marginBottom: "1.5rem" }}>
                            <label htmlFor="method-name" style={{ fontSize: "14px" }}>Method Name</label>
                            <input
                                required
                                name="method-name"
                                id="method-name"
                                value={paymentMethodData.methodName}
                                onChange={e => setPaymentMethodData({ ...paymentMethodData, methodName: e.target.value })}
                                style={{ border: "1px solid #222", height: "2.5rem", width: "100%" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="payment-method" style={{ fontSize: "14px" }}>Payment Method</label>
                            <textarea
                                required
                                name="payment-method"
                                id="payment-method"
                                value={paymentMethodData.paymentMethod}
                                onChange={e => setPaymentMethodData({ ...paymentMethodData, paymentMethod: e.target.value })}
                                rows={6}
                                style={{ border: "1px solid #222", resize: "none", width: "100%" }}
                            />
                        </div>

                        <div className="footer">
                            <div style={{ marginTop: '2rem' }}>
                                <button type="submit" className="btn primary-btn" disabled={creatingPaymentMethod}>
                                    {creatingPaymentMethod
                                        ? 'Creating...'
                                        : 'Create'
                                    }
                                </button>
                            </div>
                        </div>

                    </form>
                </Box>
            </Modal>


            {/* Update Payment Method */}
            <Modal
                open={updateModalState}
                onClose={() => setUpdateModalState(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: '2rem' }}>
                        Update Payment Method
                    </Typography>

                    <form onSubmit={updatePaymentMethod}>
                        <div style={{ marginBottom: "1.5rem" }}>
                            <label htmlFor="method-name" style={{ fontSize: "14px" }}>Method Name</label>
                            <input
                                required
                                name="method-name"
                                id="method-name"
                                value={paymentMethodData.methodName}
                                onChange={e => setPaymentMethodData({ ...paymentMethodData, methodName: e.target.value })}
                                style={{ border: "1px solid #222", height: "2.5rem", width: "100%" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="payment-method" style={{ fontSize: "14px" }}>Payment Method</label>
                            <textarea
                                required
                                name="payment-method"
                                id="payment-method"
                                value={paymentMethodData.paymentMethod}
                                onChange={e => setPaymentMethodData({ ...paymentMethodData, paymentMethod: e.target.value })}
                                rows={6}
                                style={{ border: "1px solid #222", resize: "none", width: "100%" }}
                            />
                        </div>

                        <div className="footer">
                            <div style={{ marginTop: '2rem' }}>
                                <button type="submit" className="btn primary-btn" disabled={updatingPaymentMethod}>
                                    {updatingPaymentMethod
                                        ? 'Updating...'
                                        : 'Update'
                                    }
                                </button>
                            </div>
                        </div>

                    </form>
                </Box>
            </Modal>



            {/* Delete Payment Method */}
            <Modal
                open={deleteModalState}
                onClose={() => setDeleteModalState(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: '2rem' }}>
                        Confirm
                    </Typography>
                    <Typography id="modal-description" variant="p" component="p" sx={{ marginBottom: '2rem' }}>
                        Are you sure you want to delete this payment method?
                    </Typography>

                    <p style={{ fontWeight: "600" }}>{selectedPaymentMethod?.method_name}</p>
                    <p style={{ whiteSpace: "pre", borderTop: "1px solid #222", marginBottom: "2rem", borderBottom: "1px solid #222", padding: "20px 0" }}>
                        {selectedPaymentMethod?.payment_method}
                    </p>

                    <div className="footer">
                        <div style={{ marginTop: '2rem' }}>
                            <button className="btn danger-btn" onClick={deletePaymentMethod} disabled={deletingPaymentMethod}>
                                {deletingPaymentMethod
                                    ? 'Deleting...'
                                    : 'Delete'
                                }
                            </button>

                        </div>
                    </div>
                </Box>
            </Modal>
        </AdminPage >
    );
}


