import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import whiteLogo from '../images/warnes-white.png'
import { Link, NavLink } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa'
import { MdPayment, MdReceipt } from 'react-icons/md'
import { isFinance } from '../utils/AuthSession';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function AdminNavbar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        sessionStorage.clear();
        window.location = '/';
    }

    return (
        <AppBar className='admin-nav' position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src={whiteLogo} alt='warnes-logo' className='nav-logo' />

                    <Box className='nav-link-container' sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        {!isFinance()
                            &&
                            <NavLink to='/' className='nav-link'><FaUserAlt className='link-icon' />User Accounts</NavLink>
                        }
                        <NavLink to='/bidmanagement' className='nav-link'><MdReceipt className='link-icon' />Bid Management</NavLink>
                        {!isFinance()
                            &&
                            <NavLink to='/payment-methods' className='nav-link'><MdPayment className='link-icon' />Payment Methods</NavLink>
                        }
                    </Box>

                    {/* <Typography sx={{marginRight:'2rem'}} className='nav-link'>{isFinance() ? 'Finance' : 'Admin'}</Typography> */}
                    <Box sx={{ flexGrow: 3, display: 'flex' }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                                <Avatar alt="" src="" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}

                        >
                            <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }}><NavLink to='/useraccounts' className='nav-link dark'>User Accounts</NavLink></MenuItem>
                            <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }}><NavLink to='/bidmanagement' className='nav-link dark'>Bid Management</NavLink></MenuItem>
                            <MenuItem><NavLink to='/account' className='nav-link dark'>My Account</NavLink></MenuItem>
                            <MenuItem><NavLink to='/updatePassword' className='nav-link dark'>Update Password</NavLink></MenuItem>
                            <MenuItem><NavLink onClick={logout}>Logout</NavLink></MenuItem>

                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default AdminNavbar;