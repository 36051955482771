import React from 'react'

const Legend = () => {
  return (
    <div className='legend'>
        <div className='item green'><span></span>Top Bid</div>
        <div className='item orange'><span></span>Next 3 Highest Bids</div>
        <div className='item red'><span></span>Other Bids</div>
    </div>
  )
}

export default Legend