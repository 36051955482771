import React from 'react'

const Loading = ({top=40}) => {
    const sx = {
        width:'100%',
        height: `${top}vh`,
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
    return (
        <div style={sx}>
            <p>Loading...</p>
        </div>
    )
}

export default Loading