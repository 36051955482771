import React from "react";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { read, utils } from 'xlsx';
import { DataTable } from "../components";
import { Link } from "react-router-dom";
import axios from "axios";
import { isValidDate, thousandSeperator } from "../utils/Format";

export default class UploadFileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadLaterOption: props.uploadLaterOption || false,
            session_id: props.session_id || '',
            fileData: [],
            fileDataRows: [],
            filename: "",
            submitted: false
        }
        this.handleFileSelected = this.handleFileSelected.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }



    handleFileSelected = (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const { name } = file;
        this.setState({ filename: name });

        const reader = new FileReader();
        reader.onload = (evt) => {
            if (!evt?.target?.result) {
                return;
            }
            const { result } = evt.target;
            const wb = read(result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
                const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { defval: "" }, { cellDates: true });
                console.log('rows - ', rows);
                console.log(new Date(40877))
                let data = rows.map(row => {
                    if (row[" papervalue "]) {
                        var papervalue = row[" papervalue "];
                        delete row[" papervalue "];
                        row.papervalue = papervalue;
                    }
                    // let rd = String(row.regn_date).split("/");
                    // let rd_y = rd[2],
                    //     rd_m = (String(rd[1]).length > 1) ? rd[1] : "0" + rd[1],
                    //     rd_d = rd[0];
                    // var d = new Date(Number(rd_y), Number(rd_m), Number(rd_d));
                    // let rd2 = String(row.roadtax_expiry).split("/");
                    // let rd_y2 = rd2[2],
                    //     rd_m2 = (String(rd2[1]).length > 1) ? rd2[1] : "0" + rd2[1],
                    //     rd_d2 = rd2[0];
                    // var d2 = new Date(Number(rd_y2), Number(rd_m2), Number(rd_d2));
                    // console.log(rd_y);
                    // console.log(rd_m);
                    // console.log(rd_d);
                    // console.log("date", row.regn_date);
                    // console.log("year", rd_y);
                    // console.log("mth", rd_m);
                    // console.log("day", rd_d);
                    // console.log("converted", d);
                    // console.log(d2);
                    return row;
                });
                console.log('data - ', data);
                let display = data.map((row, i) => {
                    return {
                        ...row,
                        id: i + 1
                    };
                })
                this.setState({
                    fileDataRows: display,
                    fileData: data
                });
            }
        };
        reader.readAsArrayBuffer(file);
    };
    handleFileUpload = (e) => {
        e.preventDefault();
        const { fileData, filename } = this.state;
        console.log(fileData);
        let error = ''
        fileData.forEach((data, i) => {
            console.log(data);
            if (!isValidDate(data.roadtax_expiry)) {
                error = `Invalid date value: '${data.roadtax_expiry}' for column 'roadtax_expiry' at row ${i + 1}`
            }
            if (!isValidDate(data.regn_date)) {
                error = `Invalid date value: '${data.regn_date}' for column 'regn_date' at row ${i + 1}`
            }

        })

        if (error === '') {
            if (fileData.length > 0 && filename) {
                this.setState({ submitted: true });
                this.uploadFile(fileData, this.state.session_id);
            };
        } else {
            alert(`File upload failed.\nError: ${error}`)
        }

    };

    uploadFile = async (fileData, session_id) => {
        const user = JSON.parse(sessionStorage.getItem("LoggedUser"));

        await axios.post(`${process.env.REACT_APP_API_URL}/admins/uploadcars`,
            {
                session_id: session_id,
                user_id: user.id,
                username: user.username,
                file: fileData
            }
        )
            .then(res => {
                alert(res.data);
                window.location.reload(false);
                this.setState({ submitted: false });
            }).catch(err => {
                alert(err.response.data);
                this.setState({ submitted: false });
            });
    };

    render() {
        const showTooltip = (params) => {
            if (params.row[params.field]) {
                return (
                    <Tooltip title={params.row[params.field]} placement="top">
                        <label>
                            {params.row[params.field]}
                        </label>
                    </Tooltip>
                );
            }
        }
        const columns = [
            { field: "id", headerName: "ID", maxWidth: 50 },
            {
                field: "car_reg", headerName: "car_reg",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "model", headerName: "model",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "colour", headerName: "colour",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "yom", headerName: "yom",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "regn_date", headerName: "regn_date",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "mileage", headerName: "mileage",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "omv", headerName: "omv",
                renderCell: (params) => {
                    return < Tooltip title={params.row['omv']} placement="top" >
                        <label>
                            ${thousandSeperator(params.row['omv'])}
                        </label>
                    </Tooltip >
                },
            },
            {
                field: "owners", headerName: "owners",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "papervalue", headerName: "papervalue",
                renderCell: (params) => {
                    return < Tooltip title={params.row['papervalue']} placement="top" >
                        <label>
                            ${thousandSeperator(params.row['papervalue'])}
                        </label>
                    </Tooltip >
                },
            },
            {
                field: "arf", headerName: "arf",
                renderCell: (params) => {
                    return < Tooltip title={params.row['arf']} placement="top" >
                        <label>
                            ${thousandSeperator(params.row['arf'])}
                        </label>
                    </Tooltip >
                },
            },
            {
                field: "minbidvalue_wholecar", headerName: "minbidvalue_wholecar",
                renderCell: (params) => {
                    return < Tooltip title={params.row['minbidvalue_wholecar']} placement="top" >
                        <label>
                            ${thousandSeperator(params.row['minbidvalue_wholecar'])}
                        </label>
                    </Tooltip >
                },
            },
            {
                field: "minbidvalue_bodyonly", headerName: "minbidvalue_bodyonly",
                renderCell: (params) => {
                    return < Tooltip title={params.row['minbidvalue_bodyonly']} placement="top" >
                        <label>
                            ${thousandSeperator(params.row['minbidvalue_bodyonly'])}
                        </label>
                    </Tooltip >
                },
            },
            {
                field: "remarks", headerName: "remarks",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "roadtax_expiry", headerName: "roadtax_expiry",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "roadtax_rebate", headerName: "roadtax_rebate",
                renderCell: (params) => showTooltip(params),
            },
            {
                field: "car_cost", headerName: "car_cost",
                renderCell: (params) => {
                    return < Tooltip title={params.row['car_cost']} placement="top" >
                        <label>
                            ${thousandSeperator(params.row['car_cost'])}
                        </label>
                    </Tooltip >
                },
            },
        ];
        return (
            <Grid className="car-upload-container" container justifyContent="flex-end" sx={{ mt: '-3rem' }}>
                <Button
                    component="label"
                    variant="outlined"
                    sx={{ marginRight: "1rem" }}
                    className='btn light-btn-ghost file-upload-btn'
                >
                    Select File
                    <input type="file" accept=".csv,.xlsx" hidden onChange={this.handleFileSelected} />
                </Button>
                <DataTable rows={this.state.fileDataRows || []} columns={columns || []} IsReadOnly={true} />
                <Box sx={{ mt: 2, display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center' }}>
                    {this.state.uploadLaterOption
                        &&
                        <Link to='/' style={{ marginTop: '1rem' }}>Add cars later</Link>
                    }
                    {this.state.filename && this.state.fileData.length > 0 &&
                        <Button
                            variant="contained"
                            startIcon={<UploadFileIcon />}
                            onClick={this.handleFileUpload}
                            disabled={this.state.submitted}
                            className='btn primary-btn'
                        >Upload Cars
                        </Button>
                    }
                </Box>
            </Grid >
        );
    }
};