import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import React from "react";
import { uploadFile } from "../utils/S3";
import placeholderImg from '../images/upload-placeholder.png'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from "axios";

export default class UploadCarImageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dir: props.dir || '',
            selectedImage: null,
            car_id: props.car_id || ''
        };
        this.handleFileSelected = this.handleFileSelected.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }
    handleFileSelected = (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        this.setState({ selectedImage: file });
    };
    handleFileUpload = (e) => {
        e.preventDefault();
        if (this.state.dir && this.state.selectedImage && this.state.car_id) {
            this.setState({ submitted: true });
            this.uploadImage(this.state.dir, this.state.selectedImage, this.state.car_id);
        } else {
            alert("No Image to Upload");
        }
    };

    uploadImage = (dir, file, car_id) => {
        if (file instanceof File) {
            // let ext = file.name.split('.').pop();
            // var path = dir + fileName + "." + ext;
            var path = dir + file.name;
            uploadFile(path, file).then(result => {
                console.log(result);
                if (!result) {
                    alert("Upload Failed");
                } else {
                    this.storeImageURL(car_id, result.Location, file.name)
                }
            });
        }
    }

    storeImageURL = (car_id, url, name) => {
        axios.post(`${process.env.REACT_APP_API_URL}/cars/storecarimg`,
            {
                name: name,
                car_id: car_id,
                url: url
            }
        )
            .then(res => {
                alert(res.data);
                window.location.reload(true);
            }).catch(err => {
                console.log(err.response.data);
            });
    }
    
    render() {
        return (
            <div className="new-img-container" container justifyContent="center" sx={{ mt: 3 }}>

                <div
                    style={{
                        backgroundImage: `${this.state.selectedImage ? `url('${URL.createObjectURL(this.state.selectedImage)}')` : 'none'}`
                    }}
                    className='selected-img'
                >
                    {!this.state.selectedImage
                        &&
                        <>
                            <CloudUploadIcon />
                            <p>Click on 'Select File' button to choose an image</p>
                        </>
                    }
                </div>

                <div className="d-flex button-container">
                    <button
                        component="label"
                        variant="outlined"
                        className="btn primary-btn"
                    >
                        Select File
                        <input type="file" accept="image/*" onChange={this.handleFileSelected} />
                    </button>

                    <button
                        variant="contained"
                        startIcon={<UploadFileIcon />}
                        onClick={this.handleFileUpload}
                        disabled={this.state.submitted}
                        className="btn danger-btn"
                    >
                        Upload {this.state.selectedImage?.name || ''}
                    </button>
                </div>


            </div >
        );
    }
}