import React from 'react'
import { ChangePasswordForm, UserPage } from '../components'

const UpdatePassword = () => {
  return (
    <UserPage>
      <ChangePasswordForm />
    </UserPage>
  )
}

export default UpdatePassword