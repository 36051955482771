
import React from "react";
import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from 'axios'
import CompanySelect from "./CompanySelect";
import { getUser } from "../utils/AuthSession";

export default class CreateNewUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: '',
                email: '',
                username: '',
                password: '',
                company: ''
            },
            submitted: false,
            error: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createNewUser = this.createNewUser.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ user: { ...this.state.user, [name]: value } });
    }
    handleSubmit(event) {
        console.log("handleSubmit");
        event.preventDefault();

        const { name, company, email, username, password } = this.state.user;
        if (name && username && password) {
            this.createNewUser(name, company, email, username, password);
        }
    }

    createNewUser = async (name, company, email, username, password) => {
        this.setState({ submitted: true, error: '' })
        console.log(name, ' - ', company.id, ' - ', email, ' - ', username, ' - ', password);

        axios.post(`${process.env.REACT_APP_API_URL}/admins/newdealer`, { name, company_id:company.id, email, username, password, userId: getUser().id })
            .then(res => {
                alert(res.data)
                this.setState({ submitted: false })
                window.location.reload()
            })
            .catch(err => {
                this.setState({ error: err.response.data })
                this.setState({ submitted: false })
            })
    };

    render() {
        return (
            <Container>
                <Box className="form new-dealer-form" component="form" onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
                    <Typography component="h5" variant="h5">New Dealer</Typography>
                    <Grid container direction={"row"} spacing={3} justifyContent="flex-start" mt='1rem'>
                        <Grid item className='full'>
                            <TextField
                                margin="none"
                                required
                                name="name"
                                label="Name"
                                id="name"
                                onInput={this.handleChange}
                                sx={{marginBottom:'1.5rem'}}
                            />
                        </Grid>
                        <Grid item>
                            <CompanySelect showErrorMsg={this.state.showErrorMsg} setCompany={_company => this.setState({ user: { ...this.state.user, company: _company }, showErrorMsg: false })} />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="none"
                                required
                                id="email"
                                label="Email"
                                name="email"
                                type="email"
                                onInput={this.handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="none"
                                required
                                id="username"
                                label="Username"
                                name="username"
                                type="text"
                                onInput={this.handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="none"
                                required
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                onInput={this.handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent="center">
                        <Button
                            type="submit"
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={this.state.submitted}
                            className='submit-btn'
                        >
                            Create
                        </Button>
                        <Box className='error-msg' sx={{ display: 'block', width: '100%', textAlign: 'center' }}>{this.state.error}</Box>
                    </Grid>
                </Box>
            </Container>
        );
    }
}