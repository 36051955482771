// import logo from './logo.svg';
import './App.css';
import { Routes, Route, Navigate, BrowserRouter as Router, useNavigate } from "react-router-dom";
import { LoginPage, HomePage, ProfilePage, AccountPage, UserAccountsPage, BidManagementPage, ViewBidSessionCarsPage, ViewCarPage, DealerBiddingsPage, ViewDealerBidPage } from './pages';
import { Navbar } from './components';
import { useEffect, useState } from 'react';
import DealerPasswordChange from './pages/DealerPasswordChange';
import Footer from './components/Footer';
import UpdatePassword from './pages/UpdatePassword';
import PageNotFound from './pages/PageNotFound';
import UpdateEmail from './pages/UpdateEmail';
import { getUser, isAuditor, isDealer, isFinance } from './utils/AuthSession';
import SendPasswordResetEmail from './pages/SendPasswordResetEmail';
import ResetPassword from './pages/ResetPassword';
import AllBidsInSession from './pages/AllBidsInSession';
import AllBidsOnCar from './pages/SelectWinners';
import Audits from './pages/Audits';
import io from 'socket.io-client';
import PaymentMethods from './pages/PaymentMethods';

const socket = io(process.env.REACT_APP_API_URL, {
  transports: ['polling']
});
console.log(socket);

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const home = isLoggedIn ? <Navigate to='/' /> : <Navigate to='/login' />;

  useEffect(() => {
    if (sessionStorage.getItem("LoggedUser")) {
      setIsLoggedIn(JSON.parse(sessionStorage.getItem("LoggedUser")));
    }
    return () => setIsLoggedIn(false);
  }, [])


  useEffect(() => {
    socket.on('No Active Sessions', (data) => {
      console.log('No Sessions Found Logout All Users', isDealer())

      if (isDealer()) {
        sessionStorage.clear()
        window.location = '/'
      }

    })
  }, [])



  return (
    <div className="App">
      <Router>
        <Navbar />
        {/* <p style={{ padding: '2rem', fontWeight: 'bold', background: 'white', position: 'fixed', top: 0, left: 0 }}>{JSON.stringify(refreshStatus)}</p> */}
        <Routes>
          <Route path="/" element={
            isLoggedIn
              ? <>
                {isLoggedIn?.role === 'admin'
                  ?
                  <>
                    {isFinance()
                      ? <BidManagementPage />
                      : <UserAccountsPage />
                    }
                  </>

                  :
                  <>
                    {isAuditor()
                      ? <Audits />
                      : <HomePage />
                    }
                  </>
                }
              </>
              : home}
          />
          <Route path="/login" element={
            isLoggedIn
              ? home
              : <LoginPage />
          } />


          {!getUser()
            &&
            <>
              <Route path="/reset-password" element={<SendPasswordResetEmail />} />
              <Route path="/reset-password/:resetCode" element={<ResetPassword />} />
            </>
          }
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/account" element={<AccountPage />} />
          {
            !isFinance()
            &&
            <>
              <Route path="/useraccounts" element={<UserAccountsPage />} />
              <Route path="/payment-methods" element={<PaymentMethods />} />
            </>
          }
          <Route path="/useraccounts/changePassword/:dealer_id" element={<DealerPasswordChange />} />
          <Route path="/bidmanagement" element={<BidManagementPage />} />
          <Route path="/viewcars/:session_no" element={<ViewBidSessionCarsPage />} />
          <Route path="/viewcar/:car_id" element={<ViewCarPage socket={socket} />} />
          <Route path="/biddings" element={<DealerBiddingsPage socket={socket} />} />
          <Route path="/viewbid/:car_id" element={<ViewDealerBidPage />} />
          <Route path="/updatePassword" element={<UpdatePassword />} />
          <Route path="/updateEmail" element={<UpdateEmail />} />
          <Route path="/allBids/:sessionNo" element={<AllBidsInSession />} />
          {isFinance()
            &&
            <Route path="/selectWinner/:carId" element={<AllBidsOnCar />} />
          }

          {isAuditor()
            &&
            <Route path="/audits" element={<Audits />} />
          }

          <Route path="*" element={<PageNotFound />} />
        </Routes>

        {isLoggedIn
          &&
          <Footer />
        }
      </Router>
    </div >
  );
}

export default App;
