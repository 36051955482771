import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageCarousel from "./ImageCarousel";

export default function CarImages(props) {
    const { car_id, swipable, cssClasses } = props;
    const [images, setImages] = useState([]);
    useEffect(() => {
        const getCarImages = () => {
            axios.post(`${process.env.REACT_APP_API_URL}/cars/carimages`,
                {
                    car_id: car_id
                }
            )
                .then(res => {
                    if (res.data.length > 0 && typeof res.data !== 'string') {
                        setImages(res.data.map(image => {
                            return { label: image.name, imgPath: image.src, position: image.position };
                        }));
                    } else {
                        console.log(res.data)
                    }
                }).catch(err => {
                    console.log(err.response.data);
                });
        };
        getCarImages();
        return () => setImages([]);
    }, [car_id]);
    return (
        <>
            {swipable

                ?
                <ImageCarousel images={images} />

                :
                `${images[0]?.imgPath}`
            }
        </>
    );
}