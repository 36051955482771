import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React from "react";

export default class EditImagePositionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            car_id: props.car_id || null,
            images: props.images || [],
            submitted: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        this.updatePosition();
    }

    updatePosition() {
        axios.post(`${process.env.REACT_APP_API_URL}/cars/updateimgposition`,
            {
                images: this.state.images,
                car_id: this.state.car_id
            }
        )
            .then(res => {
                alert(res.data);
                window.location.reload(false);
            }).catch(err => {
                console.log(err.response.data);
            });
    }

    render() {
        return (
            <Box sx={{ display: 'flex' }} component="form" onSubmit={this.handleSubmit} >
                <div className="d-flex car-pos-container">
                    {this.state.images.length > 0
                        &&
                        this.state.images.map((image, i) => {
                            const { name, src, position } = image;
                            return (
                                <div key={i} className="car-pos">
                                    <Typography>
                                        {image.name}
                                    </Typography>
                                    {/* <Box component="img" alt={name} src={src} /> */}
                                    <div className="car-pos-img" style={{ backgroundImage: `url('${src}')` }}></div>
                                    <TextField
                                        sx={{ mt: 1 }}
                                        label="Position"
                                        variant="outlined"
                                        type="number"
                                        value={position}
                                        onChange={(e) => {
                                            let changes = false;
                                            let list = this.state.images;
                                            let newValue = e.target.value;
                                            let old = list[i].position;
                                            if (newValue < 1) {
                                                newValue = 1;
                                            } else if (newValue > this.state.images.length) {
                                                newValue = this.state.images.length;
                                            }
                                            if (newValue !== old) {
                                                changes = true;
                                            }
                                            if (changes) {
                                                list.forEach((item, index) => {
                                                    if (Number(item.position) === Number(newValue)) {
                                                        list[index].position = old;
                                                        list[i].position = newValue;
                                                    }
                                                })
                                                this.setState({ images: list })
                                            }
                                        }}
                                    />
                                </div>
                            );
                        })}
                    <Grid item xs={12}>
                        <button
                            type="submit"
                            className="btn primary-btn"
                            disabled={this.state.submitted}>
                            Update Position
                        </button>
                    </Grid>
                </div>
            </Box>
        );
    }
}