import { Alert, Button, CssBaseline, Grid, TextField, Typography } from "@mui/material"
import { Box, Container } from "@mui/system"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getUser } from "../utils/AuthSession";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";


const DealerPasswordChange = () => {
    const { dealer_id } = useParams()

    const navigate = useNavigate()

    const [dealerData, setDealerData] = useState({
        id: dealer_id,
        username: '',
        password: '',
        confirm_password: ''
    })

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadDealerData()
    }, [])

    const loadDealerData = async () => {
        const user = getUser();
        axios.post(`${process.env.REACT_APP_API_URL}/admins/dealer`,
            {
                username: user.username,
                user_id: user.id,
                dealer_id: dealer_id
            }
        ).then(res => {
            if (res.data && res.data.length > 0 && typeof res.data !== 'string') {
                console.log(res.data[0].username);
                setDealerData({ ...dealerData, username: res.data[0].username })
            } else {
                alert('No Dealers found for the requested ID');
                navigate('/useraccounts')
            }
        }).catch(err => {
            console.log(err.response.data);
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        console.log(dealerData)
        await axios.post(`${process.env.REACT_APP_API_URL}/admins/changeDealerPassword`,
            {
                id: dealerData.id, 
                new_password: dealerData.password,
                user_id: getUser().id
            }
        ).then(res => {
            setLoading(false)
            window.location.reload(false);
            alert('Password Changed Successfully')
            navigate('/useraccounts')
            window.location.reload(false);
        }).catch(err => {
            alert(err.response.data);
            setLoading(false);
        });

    }

    return (
        <Container>
            <CssBaseline />
            <Box className="flex" sx={{ height: '4rem' }}>
                <button className="back-btn" onClick={() => navigate(-1)}>
                    <ArrowBackIcon /> Back
                </button>
            </Box>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}

                className='form'
            >
                <Typography component="h1" variant="h5">
                    Change Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                    <Grid container direction={"row"} spacing={3} justifyContent="flex-start" mt='1rem'>
                        <Grid item className='full'>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                name="username"
                                disabled={true}
                                value={dealerData.username}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="new_password"
                                label="New Password"
                                type="password"
                                id="new_password"
                                value={dealerData.password}
                                onChange={e => setDealerData({ ...dealerData, password: e.target.value })}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirm_password"
                                label="Re-enter new Password"
                                type="password"
                                id="confirm_password"
                                value={dealerData.confirm_password}
                                onChange={e => setDealerData({ ...dealerData, confirm_password: e.target.value })}
                            />
                        </Grid>

                        {(dealerData.confirm_password !== '' && dealerData.password !== '' && dealerData.confirm_password !== dealerData.password) && <Alert severity="error">Passwords don't match</Alert>}

                        <Grid container item justifyContent="center" sx={{ width: '100% !important' }}>
                            <Button
                                type="submit"
                                variant="outlined"
                                sx={{ mt: 3, mb: 2 }}
                                className='submit-btn'
                                disabled={loading || dealerData.password!==dealerData.confirm_password }
                            >
                                {loading ? 'Changing...' : 'Change Password'}
                            </Button>
                        </Grid>


                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}

export default DealerPasswordChange