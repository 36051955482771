import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Modal, Fade, Backdrop, Box } from "@mui/material";
import { IoClose } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import {thousandSeperator} from '../utils/Format'

const CarBidHistory = ({ user_id, car_id }) => {
    const [history, setHistory] = useState([])
    const [selectedBid, setSelectedBid] = useState('')
    const navigate = useNavigate()

    //Modal
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)



    useEffect(() => {
        fetchCarBidHistory()
    }, [car_id])

    const formatDate = (_date) => {
        // YYYY-MM-DD hh:mm
        var date = new Date(_date)
        let year = date.getFullYear(),
          month = (date.getUTCMonth() + 1),
          day = date.getUTCDate(),
          hour = date.getHours(),
          min = date.getMinutes();
    
    
        if (String(month).length === 1) {
          month = "0" + month;
        }
        if (String(day).length === 1) {
          day = "0" + day;
        }
        if (String(hour).length === 1) {
          hour = "0" + hour;
        }
        if (String(min).length === 1) {
          min = "0" + min;
        }
        return year + "-" + month + "-" + day + " " + hour + ":" + min;
      }

    const cancelBid = (bid_id) => {
        axios.post(`${process.env.REACT_APP_API_URL}/admins/cancelBid`, { user_id, bid_id, dateTime:formatDate(new Date()) })
            .then(res => {
                if (res.data) {
                    handleClose()
                    window.location.reload()
                }
            })
            .catch(err => {
                alert(err.response.data)
            })
    }

    const fetchCarBidHistory = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/bids/carBidHistory`, { user_id, car_id })
            .then(res => {
                // console.log(res.data)
                setHistory(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        p: 4,
    };

    return (
        <div className='car-bid-history-container'>
            <h3 className='table-title'>Bidding History</h3>
            <table>
                <tbody>
                    <tr className='table-heading'>
                        <th>Type</th>
                        <th>Price</th>
                        {/* <th>Cancel</th> */}
                    </tr>
                    <tr>
                        <td colSpan='3'><hr className='hr'></hr></td>
                    </tr>
                    {history.length > 0
                        ?
                        <>
                            {history.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='car-bid-history-data'>{item.car_or_body}</td>
                                        <td className='car-bid-history-data'>${thousandSeperator(item.bid_amount)}</td>
                                        {/* <td className='car-bid-history-data cancel'>
                                            {item.cancellation_requested
                                                ? <p>Requested</p>
                                                : <button value={i} onClick={(e) => { setSelectedBid(e.target.value); handleOpen() }} className='cancel-btn' ><IoClose className='cancel-icon' /></button>
                                            }
                                        </td> */}
                                    </tr>
                                )
                            })}
                        </>

                        : <tr><td colSpan='3'>No Bids Found</td></tr>}
                </tbody>
            </table>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className='confirmation-modal'
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <h3>Confirmation</h3>
                        <hr className='hr'></hr>
                        <h4>Are you sure you want to cancel this bid?</h4>
                        <p>Type: {history[selectedBid]?.car_or_body}</p>
                        <p>Bid Price: ${history[selectedBid]?.bid_amount}</p>
                        <p>Note: Any other bid with similar value will be given higher preference if you try to make same bid after cancelling this bid. <a href="#">Learn More</a></p>

                        <button className='btn danger-btn' onClick={() => cancelBid(history[selectedBid]?.id)}>Cancel Anyway</button>
                    </Box>
                </Fade>
            </Modal>
        </div>


    )
}

export default CarBidHistory