import React, { useEffect, useState } from 'react'
import '../styles/carousel.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSwipeable } from 'react-swipeable'

const ImageCarousel = ({ images, size }) => {

  const [activeIndex, setActiveIndex] = useState(0)
  const [paused, setPaused] = useState(false)

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = images.length - 1
    } else if (newIndex >= images.length) {
      newIndex = 0
    }

    setActiveIndex(newIndex)
  }

  //for auto scroll
  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1)
      }
    }, 4000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })


  //swipe options
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  })


  return (
    <div className='carousel-container'
      {...swipeHandlers}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      {images.length > 1
        &&
        <>
          <span className='navigation-btn left'><ArrowBackIosIcon onClick={() => updateIndex(activeIndex - 1)} /></span>
          <span className='navigation-btn right'><ArrowForwardIosIcon onClick={() => updateIndex(activeIndex + 1)} /></span>
        </>
      }

      <div className='inner'
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {images.length > 0
          ?
          <>
            {
              images.map((image, i) => {
                return (
                  <div key={i} className={`carousel-main ${size}`} style={{ backgroundImage: `url('${image.imgPath}')` }} />
                )
              })
            }
          </>

          :
          <div className={`carousel-main sm`} style={{backgroundColor:'#e3e3e3', color:'gray'}}>No Images</div>
        }
      </div>

    </div>
  )
}

export default ImageCarousel