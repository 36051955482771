import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material"
import { Box, Container } from "@mui/system"
import { useEffect, useState } from "react"
import axios from 'axios'
import { read, utils } from 'xlsx';
import ClearIcon from '@mui/icons-material/Clear';
import { getUser } from "../utils/AuthSession";
import { isValidDate } from "../utils/Format";

const CreateNewBidSessionForm = () => {
  const [sessionNo, setSessionNo] = useState('')
  const [startDateTime, setStartDateTime] = useState('')
  const [endDateTime, setEndDateTime] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [filename, setFilename] = useState('')
  const [fileDataRows, setFileDataRows] = useState('')
  const [fileData, setFileData] = useState('')

  useEffect(() => {
  })

  const formatDate = (_date) => {
    // YYYY-MM-DD hh:mm
    var date = new Date(_date)
    let year = date.getFullYear(),
      month = (date.getUTCMonth() + 1),
      day = date.getUTCDate(),
      hour = date.getHours(),
      min = date.getMinutes();


    if (String(month).length === 1) {
      month = "0" + month;
    }
    if (String(day).length === 1) {
      day = "0" + day;
    }
    if (String(hour).length === 1) {
      hour = "0" + hour;
    }
    if (String(min).length === 1) {
      min = "0" + min;
    }
    return year + "-" + month + "-" + day + " " + hour + ":" + min;
  }

  const formatDate2 = (_date) => {
    // YYYY-MM-DD hh:mm
    var date = new Date(_date)
    let year = date.getFullYear(),
      month = (date.getUTCMonth() + 1),
      day = date.getUTCDate(),
      hour = date.getHours(),
      min = date.getMinutes();


    if (String(month).length === 1) {
      month = "0" + month;
    }
    if (String(day).length === 1) {
      day = "0" + day;
    }
    if (String(hour).length === 1) {
      hour = "0" + hour;
    }
    if (String(min).length === 1) {
      min = "0" + min;
    }
    return year + "-" + month + "-" + day + "T" + hour + ":" + min;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    //check if provided file have all the columns
    let isFileValid = true
    if (fileData) {
      const fileDataHeaders = Object.keys(fileData[0])
      const fields = ["car_reg", "model", "colour", "yom", "regn_date", "mileage", "omv", "owners", "papervalue", "minbidvalue_wholecar", "minbidvalue_bodyonly", "remarks", "roadtax_expiry", "roadtax_expiry", "car_cost", "arf"]
      
      fields.forEach((field, i) => {
        if (!fileDataHeaders.includes(field)) {
          isFileValid = false
        }
      })
    }

    if (isFileValid) {
      let error = ''
      
      //check if provided file have valid date values regn_date, roadtax_expiry date
      if (fileData) {
        fileData.forEach((data, i) => {
          if (!isValidDate(data.roadtax_expiry)) {
            error = `Invalid date value: '${data.roadtax_expiry}' for column 'roadtax_expiry' at row ${i + 1}`
          }
          if (!isValidDate(data.regn_date)) {
            error = `Invalid date value: '${data.regn_date}' for column 'regn_date' at row ${i + 1}`
          }

        })
      }

      if (error === '') {
        axios.post(`${process.env.REACT_APP_API_URL}/bidSessions/newbidsession`,
          {
            session_no: sessionNo,
            session_start: formatDate(startDateTime),
            session_end: formatDate(endDateTime),
            session_status: isActive,
            file: fileData,
            user_id: getUser().id

          }
        )
          .then(res => {
            setSubmitted(true)
            alert(res.data);
            window.location.reload()
          })
          .catch(err => {
            console.log(err.response.data)
            alert(err.response.data)
          })

      } else {
        alert(`Invalid File.\nError: ${error}`)
      }
    }
    else {
      alert("Invalid file. Goto Bidmanagement > 'Sample CSV' to download Sample CSV File.")
    }



  }

  const handleFileSelected = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name)

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      const wb = read(result);
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { defval: "" }, { cellDates: true });
        console.log('rows - ', rows);
        console.log(new Date(40877))
        let data = rows.map(row => {
          if (row[" papervalue "]) {
            var papervalue = row[" papervalue "];
            delete row[" papervalue "];
            row.papervalue = papervalue;
          }
          // let rd = String(row.regn_date).split("/");
          // let rd_y = rd[2],
          //     rd_m = (String(rd[1]).length > 1) ? rd[1] : "0" + rd[1],
          //     rd_d = rd[0];
          // var d = new Date(Number(rd_y), Number(rd_m), Number(rd_d));
          // let rd2 = String(row.roadtax_expiry).split("/");
          // let rd_y2 = rd2[2],
          //     rd_m2 = (String(rd2[1]).length > 1) ? rd2[1] : "0" + rd2[1],
          //     rd_d2 = rd2[0];
          // var d2 = new Date(Number(rd_y2), Number(rd_m2), Number(rd_d2));
          // console.log(rd_y);
          // console.log(rd_m);
          // console.log(rd_d);
          // console.log("date", row.regn_date);
          // console.log("year", rd_y);
          // console.log("mth", rd_m);
          // console.log("day", rd_d);
          // console.log("converted", d);
          // console.log(d2);
          return row;
        });
        console.log('data - ', data);
        let display = data.map((row, i) => {
          return {
            ...row,
            id: i + 1
          };
        })
        setFileDataRows(display)
        setFileData(data)
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (

    <Container>
      <Box className='form new-session-form' component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Typography component="h5" variant="h5">New Session</Typography>
        <Grid container direction={"row"} spacing={3} justifyContent="flex-start" mt='1rem'>
          <Grid item className='full'>
            <TextField
              margin="none"
              required
              name="session_no"
              label="Session No"
              id="session_no"
              onInput={e => setSessionNo(e.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              margin="none"
              required
              id="startDateTime"
              label="Start Date Time"
              name="startDateTime"
              type="datetime-local"
              onInput={e => { setEndDateTime(''); setStartDateTime(e.target.value) }}
              inputProps={{
                min: formatDate2(new Date())
              }}
              className={`${startDateTime === '' ? 'text-hidden' : ''}`}
            />
          </Grid>

          <Grid item>
            <TextField
              margin="none"
              required
              id="endDateTime"
              label="End Date Time"
              name="endDateTime"
              type="datetime-local"
              onInput={e => setEndDateTime(e.target.value)}
              inputProps={{
                min: startDateTime
              }}
              className={`${endDateTime === '' ? 'text-hidden' : ''}`}
            />
          </Grid>

          <Grid item className='full' sx={{ position: 'relative' }}>
            <Button
              component="label"
              className='btn csv-btn'
            >
              {filename
                ? `Selected ${filename}`
                : 'Import CSV'
              }
              <input type="file" accept=".csv,.xlsx" hidden onChange={handleFileSelected} />
            </Button>
            {filename
              &&
              <div className="remove-file-icon" onClick={() => { setFilename(''); setFileData(''); setFileDataRows('') }} ><ClearIcon />remove file</div>
            }
            <FormControlLabel control={<Checkbox defaultChecked value={isActive} onChange={() => { setIsActive(!isActive) }} />} label="Active" />
          </Grid>


        </Grid>

        <Grid container item justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            className="btn light-btn-ghost"
            sx={{ mt: 3, mb: 2 }}
            disabled={submitted}
          >
            Create
          </Button>
        </Grid>
      </Box>
    </Container>
  );
}

export default CreateNewBidSessionForm;
