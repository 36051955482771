import axios from 'axios'
import React, { useEffect } from 'react'
import { getUser } from "../utils/AuthSession"
import { thousandSeperator } from '../utils/Format'

const BidStatus = ({ status, bid_amount, car_or_body }) => {

  return (
    <div className={`bid-status-container ${status}`}>
      <p>Your Highest Bid ({car_or_body}) - <span>${thousandSeperator(bid_amount)}</span></p>
    </div>
  )
}

export default BidStatus